const locales = {
  en: {
    קוני: "Connie",
    octagon: "Octagon",
    "מכשירים פעילים": "Active devices",
    בהתקנה: "In Installation",
    "לא פעילים": "Not active",
    רגיל: "Regular",
    שביר: "Fragile",
    "לא מוגדר": "Unspecified",
    צבוע: "Painted",
    "לא צבוע": "Not painted",
    "ערך אחר": "Other value",
    fixture_icon: "Fixture Icon",
    Connector_Size: "Connector Size",
    cabinet_type: "Cabinet Type",
    assigned_devices: "Total Fixtures",
    total_poles: "Total Poles",
    controlled_devices: "Controlled Fixtures",
    controlled_poles: "Controlled Poles",
    "gps_location lat": "GPS Location Lat",
    "gps_location lng": "GPS Location Lng",
    "app_location lat": "APP Location Lat",
    "app_location lng": "APP Location Lng",
    "gis_location lat": "GIS Location Lat",
    "gis_location lng": "GIS Location Lng",
    "manual_location lat": "MANUAL Location Lat",
    "manual_location lng": "MANUAL Location Lng",
    file: "file",
    estimated_address: "Estimated Address"
  },
  he: {
    "Water Flow": "זרימת מים",
    "Consumption": "צריכה",
    "Liter": "ליטר",
    "Configure Schedule": "הגדר תזמון",
    "Turn On/Off": "הדלק/כבה",
    "Unknown": "לא ידוע",
    "Fixtures On": "בקרים דולקים",
    "Sending commands to active devices": "שולח פקודות למכשירים פעילים",
    "out of": "מתוך",
    "Scan Number": "מספר סריקה",
    "Service Status has been successfully updated": "סטטוס השירות התעדכן בהצלחה",
    "Alerts Service On": "שירות התרעות פעיל",
    "Alerts Service Off": "שירות התרעות כבוי",
    "Cabinet IQ Alert Service On": "שירות התרעות מרכזיה פעיל",
    "Cabinet IQ Alert Service Off": "שירות התרעות מרכזיה כבוי",
    "Cabinet IQ Alerts": "התרעות מרכזיה",
    "Power Service On": "שירות תאורה פעיל",
    "Power Service Off": "שירות תאורה כבוי",
    "There are no inputs and outputs for this device":
      "אין כניסות ויציאות עבור מכשיר זה",
    Phone: "טלפון",
    City: "עיר",
    Estimated: "משוערת",
    estimated: "משוערת",
    estimated_address: "כתובת משוערת",
    "Devices mode updated successfully!": "סטטוס המכשירים התעדכן בהצלחה!",
    "All groups updated successfully!": "כל הקבוצות התעדכנו בהצלחה!",
    "Success!": "הצלחה!",
    "Device location backup was done successfully": "גיבוי מיקום התבצע בהצלחה",
    "Device location successfully updated": "מיקום המכשיר עודכן בהצלחה",
    "You have reached the maximum number of dimming fluctuations in the curve, to add a fluctuation you must delete one":
      "הגעת למספר המרבי של תנודות העמעום בעקומה, כדי להוסיף תנודה נוספת עליך למחוק אחת",
    "New Curve": "גרף עמעום חדש",
    "You must remove this curve from the following schedules before delete":
      "לפני המחיקה, עליך להסיר את גרף העמעום מהתזמונים הבאים",
    "Save Schedule": "שמור תזמון",
    "Select Curve": "בחר גרף עמעום",
    Curves: "גרפים",
    "Are you sure you want to delete this curve":
      "האם אתה בטוח שאתה רוצה למחוק את הגרף",
    "Alerts Service": "שירות התרעות",
    "Please stand by": "אנא המתן",
    "Set Dimming Level": "הגדר רמת עמעום",
    Set: "הגדר",
    "Search By Pole Or Circuit Number": "חפש לפי מספר עמוד או מעגל",
    "Please fullfill all required fields": "עליך למלא את כל השדות הנדרשים",
    "Are you sure you want to update data":
      "האם אתה בטוח שאתה רוצה לעדכן נתונים",
    "Number has to be between": "המספר חייב להיות בטווח שבין",
    and: "ל-",
    "I know that this ID doesn't exist in the system and I'm responsible for the consequences":
      "אני יודע שמספר המרכזיה לא קיים במערכת ואני מודע להשלכות",
    Astral: "שעון אסטרונומי",
    Clock: "שעון",
    Combined: "משולב",
    Other: "אחר",
    "Explanation of the basic data of the report": "הסבר על נתוני הדוח",
    "The electricity savings of HPS (High Pressure Sodium lamps) versus LED": `חסכון ההספק נל"ג לעומת LED`,
    "calculated according to": `חושב עפ"י`,
    "the 2016 planner instruction file of the Israeli Ministry of Construction":
      "קובץ הנחיות למתכנן משרד הבינוי 2016",
    "Appendix 7, the power consumption including electrical losses": `נספח 7, הספק צריכה כולל הפסדים חשמליים`,
    "HPS vs": `נל"ג לעומת`,
    "LED for an existing facility": "LED עבור מתקן קיים",
    "Dimming according to publication": "דימור(עמעום) בהתאם לפרסום",
    // "Design Guidelines for LED Street Lighting": "",
    // "PremiumLight-Pro Consortium": "",
    // "Austrian Energy Agency And more": "",
    "It is possible to achieve an 85% saving in the use of dimming and accuracy in lighting times with an astronomical clock": `אפשר להגיע ל85% חסכון בשילוב שימוש בדימור ודיוק בזמני התאורה ע"י שעון אסטרונומי`,
    "We chose a conservative number of 20% savings to improve the average consumption, beyond that the numbers are depends on the nature of the utility of the lighting area":
      "אנחנו בחרנו במספר שמרני של 20% חסכון עבור ממוצע שיפור הצריכה, מעבר לזה זה תלוי באופן שימושיות אזור התאורה",
    "Details of greenhouse gas emissions in grams per net kWh - the": `פירוט פליטות גזי חממה בגרם לקוט"ש נטו -`,
    "Electric Company website": "אתר האינטרנט של חברת החשמל",
    "Report By Group": "דוח לפי קבוצה",
    "Report By Cabinet ID": "דוח לפי מספר מרכזיה",
    "Select Period": "בחר תקופה",
    "Select Cabinet ID": "בחר מספר מרכזיה",
    "Select Group": "בחר קבוצה",
    "Choose Period": "בחר תקופה",
    "Choose Cabinet ID": "בחר מספר מרכזיה",
    "Search By Cabinet ID": "חפש לפי מספר מרכזיה",
    "No results matching": "אין תוצאות מתאימות עבור",
    Press: "לחץ",
    "to create a new one": "על מנת ליצור אחד חדש",
    "Type Cabinet ID, Description Or Select Field": "הקלד מספר מרכזיה, תיאור או בחר שדה",
    "No cabinets were found": "לא נמצאו מרכזיות",
    "Search By Cabinet Data": "חפש לפי נתוני מרכזיה",
    "No results were found": "לא נמצאו תוצאות",
    "You must select at least one value": "עליך לבחור לפחות ערך אחד",
    "Are you sure you want to delete this report":
      "האם אתה בטוח שאתה רוצה למחוק את הדוח",
    "Report Name": "שם הדוח",
    "Saving A Report": "שמירת דוח",
    "Choose Report": "בחר דוח",
    "The report was successfully saved": "הדוח נשמר בהצלחה",
    "The report was successfully deleted": "הדוח נמחק בהצלחה",
    "Save This Report": "שמור את הדוח",
    "Saved Reports": "דוחות שמורים",
    "Unsupported file type": "סוג קובץ לא נתמך",
    "gps_location lat": "מיקום GPS קו רוחב",
    "gps_location lng": "מיקום GPS קו אורך",
    "app_location lat": "מיקום APP קו רוחב",
    "app_location lng": "מיקום APP קו אורך",
    "gis_location lat": "מיקום GIS קו רוחב",
    "gis_location lng": "מיקום GIS קו אורך",
    "manual_location lat": "מיקום MANUAL קו רוחב",
    "manual_location lng": "מיקום MANUAL קו אורך",
    "Not in group": "לא משויך לקבוצה",
    "Installation Information": "מידע על התקנות",
    Latitude: "קו רוחב",
    Longitude: "קו אורך",
    "All changes have been saved successfully": "כל השינויים נשמרו בהצלחה",
    "Some changes not saved": "חלק מהשינויים לא נשמרו",
    "Are you sure you want to save all changes":
      "האם אתה בטוח שאתה רוצה לשמור את השינויים",
    "Are you sure you want to change device position":
      "האם אתה בטוח שאתה רוצה לשנות את מיקום המכשיר",
    Communication: "תקשורת",
    Communicated: "בקרים שתקשרו",
    "Total Fixtures": `סה"כ גופי תאורה`,
    "Controlled Fixtures": `סה"כ גופי תאורה מבוקרים`,
    "Actual Controlled Fixtures": `סה"כ גופי תאורה מבוקרים בפועל`,
    "Total Poles": `סה"כ עמודים`,
    "Controlled Poles": `סה"כ עמודים מבוקרים`,
    "Actual Controlled Poles": `סה"כ עמודים מבוקרים בפועל`,
    "This field is required": "שדה זה הינו חובה",
    "Select Type": "בחר סוג",
    "Add Cabinet": "הוסף מרכזיה",
    "Cabinet Type": "סוג מרכזיה",
    cabinet_type: "סוג מרכזיה",
    "Installer Name": "שם המתקין",
    assigned_devices: `סה"כ גופי תאורה`,
    controlled_devices: `סה"כ גופי תאורה מבוקרים`,
    "Controlled Devices": `סה"כ גופי תאורה מבוקרים`,
    total_poles: `סה"כ עמודים`,
    controlled_poles: `סה"כ עמודים מבוקרים`,
    "Edit Cabinet": "ערוך מרכזיה",
    Virtual: "וירטואלי",
    "Cabinets list is successfully updated": "רשימת המרכזיות עודנה בהצלחה",
    "Cabinet with same id already exists": "קיימת מרכזיה עם אותו מספר מזהה",
    "You must fill all fields": "עליך למלא את כל השדות",
    "This field must be of type number": "שדה זה חייב להיות מספרי",
    "This field must be positive number": "שדה זה חייב להיות מספר חיובי",
    "This field must contain only the characters":
      "שדה זה חייב להכיל רק את התווים",
    "Installed Devices": "מכשירים מותקנים",
    "Uninstalled Devices": "מכשירים לא מותקנים",
    "You must choose at least one device": "עליך לבחור לפחות מכשיר אחד",
    "Create Filter": "צור סנן",
    "Couldn't create filter because filter view with same name already exists":
      "הסנן לא נוצר מכיוון שקיים סנן עם שם זהה",
    "Installations Report": "דוח התקנות",
    "Filter successfully created": "הסנן נוצר בהצלחה",
    "Filter successfully updated": "הסנן עודכן בהצלחה",
    "Filter successfully deleted": "הסנן נמחק בהצלחה",
    "Couldn't create filter": "יצירת הסנן נכשלה",
    "Couldn't update filter": "עדכון הסנן נכשל",
    "Couldn't delete filter": "מחיקת הסנן נכשלה",
    "Group successfully deleted": "הקבוצה נמחקה בהצלחה",
    "Group successfully updated": "הקבוצה עודכנה בהצלחה",
    "Group successfully created": "הקבוצה נוצרה בהצלחה",
    "Create Fixtures Group": "צור קבוצת בקרים",
    "Edit Fixtures Group": "ערוך קבוצת בקרים",
    "Fixture Icon": "אייקון המכשיר",
    fixture_icon: "אייקון המכשיר",
    "Field has been updated in all devices": "השדה עודכן בהצלחה בכל המכשירים",
    "Couldn't update field value": "השדה לא עודכן",
    "Update Metadata": "עדכן מידע על המכשיר",
    "Data successfully updated": "המידע עודכן בהצלחה",
    "Couldn't update data": "המידע לא עודכן",
    "Couldn't update group": "עדכון הקבוצה נכשל",
    "Couldn't delete group": "מחיקת הקבוצה נכשלה",
    "Are you sure you want to update this value for all group devices":
      "האם אתה בטוח שברצונך לעדכן את הערך עבור כל המכשירים שבקבוצה",
    "Are you sure you want to create filter view for this group":
      "האם אתה בטוח שברצונך ליצור סנן עבור הקבוצה",
    "Multiple Selection": "בחירה מרובה",
    "Show History": "הצג היסטוריה",
    "Hide History": "הסתר היסטוריה",
    "Cabinet Alerts": "התרעות מרכזיה",
    "Cabinet Events": "אירועי מרכזיה",
    "Are you sure you want to create this filter view?":
      "אתה בטוח שאתה רוצה ליצור את הסנן?",
    "Are you sure you want to delete this filter view?":
      "אתה בטוח שאתה רוצה למחוק את הסנן?",
    "Are you sure you want to update this filter view?":
      "אתה בטוח שאתה רוצה לעדכן את הסנן?",
    "This name already exists": "השם כבר קיים במערכת",
    "There are no alerts for this project": "לא קיימות התרעות בפרויקט זה",
    "There are no events for this project": "לא קיימים אירועים בפרויקט זה",
    "Excel Upload": "העלאת קובץ אקסל",
    "Set Part Number By Device ID": "עדכון מספר עמוד לפי מזהה מכשיר",
    "Set Pole Number By Hardwareid": "עדכון מספר עמוד לפי מזהה חומרה",
    "Set Device Data By Pole Number": "עדכון מידע מכשיר לפי מספר עמוד",
    "Advanced Device": "מתקדם - מכשיר",
    "Advanced Group": "מתקדם - קבוצה",
    "Manager Reports": "דוחות מנהל",
    "Set Manual Override": "הגדר דימור ידני",
    "Managed Area": "אזור מנוהל",
    "View All Projects": "הצג את כל הפרויקטים",
    //Full View Report
    "Projects Overview": "תמונת מצב",
    "Full View Report": "תמונת מצב",
    Inventory: "מלאי",
    "Projects Review": "סקירת פרויקטים",
    Title: "שם",
    "Weekly Devices Installation": "התקנות בשבוע האחרון",
    "Last Three Weeks Devices Installation": "התקנות בשלושת השבועות האחרונים",
    inventory: "מלאי",

    "Cabinet On": "מרכזיה דולקת",
    "Cabinet Off": "מרכזיה כבויה",
    "In installation": "בהתקנה",
    "In Installation": "בהתקנה",
    Container: "מקבץ",
    Attention: "שים לב",
    "Container ID": "מזהה מקבץ",
    // Network Management
    "Base Station": "אנטנה",
    "Select Provider": "בחר ספק",
    "Network Management": "ניהול רשת",
    "Recreate Map Data": "עדכן נתונים",
    "Last Update": "עדכון אחרון",
    "Show Base Station": "הצג אנטנה",
    "Last Streams Update": "עדכון אחרון לסטרים",
    "Pole ID": "מספר עמוד",
    "Uncommissioned Devices": "מכשירים שלא הותקנו",
    Network: "רשת",
    "Search By Name": "חפש לפי שם",
    "Search By Name or Pole Number": "חפש לפי שם או מספר עמוד",
    "Last RSSI": "RSSI אחרון",
    "Data will be updated in a few minutes": "הנתונים יתעדכנו תוך מס' דקות",
    "Warning!": "אזהרה!",
    Warning: "אזהרה",
    "Best Communication": "תקשורת טובה",
    "Worst Communication": "תקשורת גרועה",
    "Quality Grade": "איכות התקשורת",
    "3 Days Missing": "לא תקשרו 3 ימים",
    "Never Communicated": "בקרים שלא תקשרו מעולם",
    "Not Commissioned": "לא הותקנו",
    "SAI Req Fault": "בקשת SAI נכשלה",
    "Could not find antenna for this device due to info missing":
      "לא נמצאה אנטנה עבור המכשיר עקב מידע חסר",
    "devices with valid pole number": "מכשירים עם מספר עמוד תקין",
    Back: "חזור",
    back: "חזור",
    BACK: "חזור",
    Overview: "כללי",
    Energy: "אנרגיה",
    Events: "אירועים",
    "Event Management": "ניהול אירועים",
    Cabinets: "מרכזיות",
    "Alert Management": "ניהול התרעות",
    Event: "אירוע",
    Date: "תאריך",
    after: "אחרי",
    before: "לפני",
    "Last 24 hours": "24 שעות אחרונות",
    "Last 24 Hours": "24 שעות אחרונות",
    "Last 48 Hours": "48 שעות אחרונות",
    "Last Hour": "שעה אחרונה",
    "Last Day": "יום אחרון",
    "Last 2 Days": "יומיים אחרונים",
    "Last week": "שבוע אחרון",
    "Last Week": "שבוע אחרון",
    "Last 7 Days": "7 ימים אחרונים",
    "Last month": "חודש אחרון",
    "Last Month": "חודש אחרון",
    "Last 30 Days": "30 ימים אחרונים",
    "Last 3 months": "3 חודשים אחרונים",
    "Last 3 Months": "3 חודשים אחרונים",
    "Last 6 months": "6 חודשים אחרונים",
    "Last 6 Months": "6 חודשים אחרונים",
    "Last year": "שנה אחרונה",
    "Last Year": "שנה אחרונה",
    "Custom Range": "טווח מותאם אישית",
    custom: "בהתאמה אישית",
    Search: "חיפוש",
    "Search Installed": "חפש מותקנים",
    About: "אודות",
    "For information on Tondo's use of open source, please email":
      "למידע על השימוש של טונדו בקוד פתוח בבקשה שלח מייל",
    "All site content (c) 2021 Tondo Smart Ltd":
      'כל הזכויות שמורות לטונדו סמארט בע"מ',
    TONDO: "TONDO",
    FIXTURE: "בקר",
    fixture: "בקר",
    "Cabinet Manager": "מרכזיה",
    "Cabinet Management": "ניהול מרכזיות",
    FIXTURES: "בקרים",
    HUBS: "מרכזיות",
    CABINETS: "מרכזיות",
    CABINET: "מרכזיה",
    "Cabinet Managers": "מרכזיות",
    VIDEOS: "מצלמות",
    SENSORS: "חיישנים",
    SENSOR: "חיישן",
    sensor: "חיישן",
    Sensors: "חיישנים",
    "Sensor Activity": "פעילות החיישן",
    RADARS: "רדאר",
    "Traffic Radar": "רדאר תנועה",
    "Vehicle Size": "גודל מכונית",
    Count: "כמות",
    "N/A": "אין מידע זמין",
    "Total vehicles": 'סה"כ מכוניות',
    "Active vehicle monitor": "מוניטור",
    Direction: "כיוון",
    "AVG Speed": "מהירות ממוצעת",
    "Max Speed": "מהירות מקסימלית",
    Classification: "סוג",
    Help: "עזרה",
    "Project Contacts": "אנשי קשר",
    "Add New Contact": "הוסף איש קשר",
    "Edit Contact": "ערוך איש קשר",
    Reset: "בטל שינויים",
    "Add New Column": "הוסף עמודה",
    "Column Name": "שם עמודה",
    "Installation Guide": "מדריך התקנה",
    "User Guide": "מדריך משתמש",
    "There are no sensors enabled in this project":
      "לא קיימים חיישנים זמינים בפרויקט זה",
    "There are no cabinets enabled in this project":
      "לא קיימות מרכזיות בפרויקט זה",
    "Period total": "סך הכל לתקופה המבוקשת",
    watts: "וואט",
    Amps: "אמפר",
    Wh: "וואט לשעה",
    Ah: "אמפר לשעה",
    "GET DEVICE TEMPERATURE": "קבל את טמפרטורת המכשיר",
    Temperature: "טמפרטורה",
    temperature: "טמפרטורה",
    "Are you sure you want to delete this group?":
      "האם אתה בטוח שברצונך למחוק את הקבוצה?",

    Connections: "חיבורים",
    "Add Connection": "הוסף חיבור",
    "No Connections Available": "אין חיבורים זמינים",
    "Connection Description": "תיאור חיבור",
    Done: "סיום",
    "Add Element": "הוסף אלמנט",
    Quit: "יציאה",
    Element: "אלמנט",
    Configuration: "קונפיגורציה",
    "Contactor Description": "תיאור",
    "Breaker Monitor Description": "תיאור",
    "Line Monitor Relay Description": "תיאור",
    "Add Fixtures To Collection": "הוסף מכשירים לקבוצה",
    "Delete Element": "מחק אלמנט",
    "Are you sure you want to exit without save?":
      "האם אתה בטוח שברצונך לצאת בלי לשמור?",
    "Are you sure you want to exit?":
      "האם אתה בטוח שברצונך לצאת?",
    "Delete Connection": "מחק חיבור",
    "Collections Map - Overview": "מפה",
    "Collections Map - Edit Mode": "מפה - מצב עריכה",
    "Edit Collection": "ערוך קבוצה",
    Connection: "חיבור",
    "View / Edit Collection": "צפייה / עריכת קבוצה",
    "Connection ID": "מספר מזהה - חיבור",
    "Element ID": "מספר מזהה - אלמנט",
    "Add Child": "הוסף ילד",
    "Are you sure you want to delete this connection?":
      "האם אתה בטוח שברצונך למחוק את החיבור הזה?",
    "Choose Input": "בחר אינפוט",
    "Choose Type": "בחר סוג",
    "Choose Control": "בחר שליטה",
    "Choose Config": "בחר קונפיגורציה",
    "1=on/0=off": "1=דולק / 0=כבוי",
    "1=off/0=on": "1=כבוי / 0=דולק",
    Fixture: "בקר",
    ASSET: "מכשיר וירטואלי",
    HUB: "נתב",
    hub: "מרכזיה",
    GATEWAY: "נתב",
    "TOTAL devices": "סך כל המכשירים",
    "Total devices": "סך כל המכשירים",
    "Fault devices": "מכשירים תקולים",
    "Online devices": "מכשירים תקינים",
    Version: "גרסה",
    Cancel: "בטל",
    Continue: "המשך",
    Save: "שמור",
    Undo: "שחזר",
    Action: "פעולה",
    Closed: "סגור",
    CLOSE: "סגור",
    OPEN: "פתח",
    Close: "סגור",
    Enable: "פעיל",
    "Enter name": "הכנס שם",
    "Enter label": "הכנס שם",
    "Choose Action": "בחר פעולה",
    "Do nothing": "אל תבצע פעולה",
    "Create event": "צור אירוע",
    "Send alert": "שלח התרעה",
    "Inputs Management": "ניהול כניסות",
    Inputs: "כניסות",
    Input: "כניסה",
    Outputs: "יציאות",
    Output: "יציאה",
    "Choose inputs to display": "בחר אינפוטים להצגה",
    Refresh: "רענן",
    "LIVE READINGS": "קריאות בזמן אמת",
    "Integrated Controls": "בקרים מחוברים",
    "Integrated Inputs": "אינפוטים מחוברים",
    "No Inputs Available": "אין כניסות זמינות",
    "Cabinet documents": "מסמכים של המרכזיה",
    Operations: "תפעול",
    "Choose data to show in graph": "בחר נתונים להציג בגרף",
    Billing: "חיובים",
    "Total Energy": 'סה"כ אנרגיה',
    "Tariff 1 (Peak)": "תעריף 1 (פסגה)",
    "Tariff 1": "תעריף 1",
    "Tariff 2 (High)": "תעריף 2 (גבע)",
    "Tariff 2": "תעריף 2",
    "Tariff 3 (Low)": "תעריף 3 (שפל)",
    "Tariff 3": "תעריף 3",
    "Peak": "פסגה",
    "Cabinet Door State": "דלת המרכזיה",
    "Input 2 NOT IN USE": "אינפוט 2 לא בשימוש",
    "Cabinet Door Lock": "מנעול המרכזיה",
    Unlock: "לא נעול",
    UNLOCK: "פתח מנעול",
    "Remains unlocked for more then": "נשאר פתוח יותר מ",
    "Remains open for more then": "נשאר פתוח יותר מ",
    minutes: "דקות",
    seconds: "שניות",
    Lock: "נעול",
    "Please enter valid number of minutes (has to be greater than 0)":
      "בבקשה הכנס מספר חוקי של דקות (גדול מ0)",
    Humidity: "לחות",
    "Update temperature": "עדכן טמפרטורה",
    "Update humidity": "עדכן לחות",
    Update: "עדכן",
    "Update current view": "עדכן תצוגה נוכחית",
    "Do you want to update this filter view?": "מעוניין לעדכן תצוגה",
    Settings: "הגדרות",
    "Export Data": "יצא נתונים",
    Schedule: "תזמון",
    "Schedule Management": "ניהול תזמונים",
    "Available Dimming Curve": "גרפי עמעום קיימים",
    "Energy Consumption": "צריכת אנרגיה",
    Period: "תקופה",
    "Or pick custom period": "או בחר תקופה בהתאמה אישית",
    "pick custom period": "בחר תקופה בהתאמה אישית",
    "Ticket system not integrated": "המערכת לא זמינה",
    "Please contact support": "בבקשה צור קשר עם התמיכה",
    "For managing your ticketing system click the following link":
      "כדי לנהל את מערכת ההתרעות שלך, עליך להיכנס ללינק",
    "If you do not have any user yet, please email to":
      "אם אין לך עדיין משתמש, עליך לשלוח מייל ל-",
    "Pick period": "בחר תקופה",
    From: "החל מ-",
    To: "עד",
    Generate: "צור",
    Select: "בחר",
    "Add item to Compare": "הוסף פריט להשוואה",
    "Consumption for picked period": "צריכה לתקופה שנבחרה",
    "Reset filter to default view": "אתחל סינון לתצוגת ברירת מחדל",
    cabinet: "מרכזיה",
    "Default Location": "מיקום ברירת מחדל",
    Advanced: "מתקדם",
    "Device Data": "מידע על המכשיר",
    "Turn Off Time": "זמן כיבוי",
    "Turn On Time": "זמן הדלקה",
    "Turn Off": "כבה",
    "Turn On": "הדלק",
    "Advanced info": "מידע מתקדם",
    "ADVANCED INFO": "מידע מתקדם",
    Run: "הרץ",
    power_consumption: "צריכת אנרגיה",
    "No data to display": "אין מידע להצגה",
    Filter: "סנן",
    "IP Address": "כתובת IP",
    "Has Schedule": "קיים תזמון",
    typeOptions: "סוגים אפשריים",
    Manufacturer: "יצרן",
    "Driver Type": "סוג דרייבר",
    Watts: "וואט",
    "Set Current location": "קבע מיקום נוכחי",
    Address: "כתובת",
    Model: "דגם",
    Contractor: "קבלן",
    Technician: "טכנאי",
    Interface: "ממשק",
    District: "רובע",
    "Astronomical Clock": "שעון אסטרונומי",
    "Pole Number": "מספר עמוד",
    "Cabinet ID": "מספר מרכזיה",
    "Device Name": "שם המכשיר",
    "Dimming Level": "רמת עמעום",
    "Part Number": "מספר קטלוגי",
    Inactive: "לא פעיל",
    "Pole Height": "גובה עמוד",
    "Total Power Consumption(kWh)": `סה"כ הספק לתקופה(kWh)`,
    "Cabinet IEC's Meter ID": "מונה חברת חשמל",
    "Cabinet IEC Meter ID": "מונה חברת חשמל",
    "IEC's Customer ID": "מספר צרכן חברת חשמל",
    "IEC's Supply Pole ID": "עמוד מזין חברת חשמל",
    "Connector Type": "סוג חיבור",
    Phase: "פאזה",
    Circuit: "מעגל חשמלי",
    "Not Available": "לא זמין",
    feet: "רגל",
    meter: "מטר",
    "Choose a project": "בחר פרויקט",
    "Choose a company": "בחר חברה",
    "Companies not found": "לא נמצאו חברות",
    "Projects not found": "לא נמצאו פרויקטים",
    "All projects": "כל הפרויקטים",
    Time: "שעה",
    "Error Type": "סוג שגיאה",
    "Device name": "שם המכשיר",
    "Extra data": "מידע נוסף",
    "Field is required": "שדה זה נדרש",
    "Field Is Required": "שדה זה נדרש",
    Groups: "קבוצות",
    groups: "קבוצות",
    "Filter Views": "פילטרים",
    "Groups List": "רשימת קבוצות",
    Group: "קבוצה",
    "Full View": "תצוגה מלאה",
    Location: "מיקום",
    Fault: "תקלה",
    value: "ערך",
    "Rows per page": "שורות בעמוד",
    "Export YTD": "יצא שנה אחורה",
    "Export MTD": "יצא חודש אחורה",
    LOGIN: "התחברות",
    "Forgot Password": "שכחת את הסיסמא",
    ENTER: "כניסה",
    "Fault Type": "סוג תקלה",
    "Fault type": "סוג תקלה",
    Loading: "טוען",
    Filters: "פילטרים",
    "Maximize Map": "הגדל מפה",
    "Toggle RSSI": "עוצמת קליטה",
    "Satellite Map": "מפה לווינית",
    "Address search": "חיפוש כתובת",
    "Map Settings": "הגדרות מפה",
    "Reposition device": "מקם מכשיר",

    // reports
    "Select Cabinet ID": "בחר מספר מרכזיה",
    "There is no device history between": "לא קיימת היסטוריה בתאריכים",
    "Available Data For": "מידע זמין לתאריכים",
    "Activity Log Report": "דוח יומן פעילות",
    "Cabinet Power Consumption Report": "דוח צריכת הספק לפי מרכזיה",
    "Cabinet Power Consumption": "צריכת הספק לפי מרכזיה",
    "Controller Power Consumption Report": "דוח צריכת הספק לפי בקר",
    "Controller Power Consumption": "צריכת הספק לפי בקר",
    "Power Consumption Report": "דוח צריכת הספק",
    "Activity Log": "יומן פעילות",
    "Search Device By Name, Pole Number or Cabinet ID":
      "חפש מכשיר לפי שם, מספר עמוד או מספר מרכזיה",
    Required: "שדה חובה",
    "Total [hours]": `סה"כ[בשעות]`,
    "Power Consumption [kWh]": "צריכת הספק [kWh]",
    "Cable Type": "סוג כבל",
    "Fixture Type": "סוג בקר",
    DALI: "דאלי",
    Photometry: "פוטומטריה",
    "Is Pole Painted": "האם העמוד צבוע",
    "Fixture Manufacturer": "יצרן המכשיר",
    "Road Curbs": "האם יש לכביש שוליים",
    Switch: "מפסק",
    "Driver Manufacturer": "יצרן דרייבר",
    "Driver Model": "דגם דרייבר",
    "Driver Configuration": "תצורת דרייבר",
    "Number of lighting fixture": "כמות גופי תאורה בעמוד",
    "Pole Type": "סוג עמוד",
    "meta.name": "שם",
    "Circuit Number": "מספר מעגל",
    "Number of Circuits": "מספר מעגלים",
    "Number Of Circuits": "מספר מעגלים",
    "Cable Width": "עובי כבל",
    Date_Treated: "תאריך טיפול",
    Energy_meter_number: "מספר מונה אנרגיה",
    Last_date_check: "תאריך בדיקה אחרון",
    "Connector Size": "גודל חיבור",
    "Cabinet Address": "כתובת המרכזיה",
    "Cabinet Name": "שם המרכזיה",
    "Road Number": "מספר כביש",
    "Alert Time": "זמן התרעה",
    "Latest Alert Time": "זמן התרעה אחרונה",
    "Latest Event Time": "זמן אירוע אחרון",
    "Maximum Ampers": "מקסימום אמפר",
    "Amount of switchs": "כמות מפסקים",
    Road: "כביש",
    "Fixture Model": "מודל המכשיר",
    "Clear Table": "נקה טבלה",
    "Inventory Management": "ניהול מלאי",
    "Inventory Management Report": "דוח ניהול מלאי",
    "Filter By": "סנן לפי",
    "You can choose up to 4 fields": "ניתן לבחור עד 4 שדות",
    Remove: "הסר",
    devices: "מכשירים",
    "There are no devices with these parameters": `לא נמצאו מכשירים עם הפרמטרים הנ"ל`,
    "No devices were installed yesterday": "לא הותקנו אתמול מכשירים",
    "Daily Report": "דוח יומי",
    "Weekly Report": "דוח שבועי",
    Installations: "התקנות",
    All: "הכל",
    "Alerts Report": "דוח התרעות",
    "Events Report": "דוח אירועים",
    "There are no open tickets on date": "אין כרטיסים פתוחים בתאריך",
    "Pick date": "בחר תאריך",
    "Carbon Emmission": "פליטת פחמן",
    "Installation Fail": "התקנה נכשלה",
    "Installation Success": "התקנה התבצעה",
    "Total Power Consumption": "צריכת הספק כוללת",
    "Daily Power Consumption[KWh]": "צריכת הספק יומית[KWh]",
    "Tondo Device": "בקר טונדו",
    "Cabinet Monitor": "מרכזיה",
    Camera: "מצלמה",
    "No devices were installed this week": "לא הותקנו השבוע מכשירים",
    Contractor: "קבלן",
    Area: "אזור",
    "Device Type": "סוג יחידה",
    "Asset Type": "סוג יחידה וירטואלית",
    Installed: "מותקן",
    Active: "יחידות מאושרות כתקינות",
    Total: 'סה"כ',
    Reporting: "דוחות",
    "Choose report type": 'בחר סוג דו"ח',
    "Tondo Reports": "דוחות מכשירים",
    "Cabinet Reports": "דוחות מרכזיות",
    "Billing Report": 'דו"ח חיובים',
    Devices: "מכשירים",
    Totals: 'סה"כ',
    "Live Faults": "תקלות",
    "Live Faults Report": 'דו"ח תקלות',
    "Temperature Exceeding": "טמפרטורה החורגת מ",
    "Not found devices with temperature exceeding":
      "לא נמצאו מכשירים עם טמפרטורה העולה על",
    "Disconnected Devices": "מכשירים מנותקים",
    "Connected Devices": "מכשירים מחוברים",
    "Disconnected devices found": "מכשירים מנותקים נמצאו",
    "Unit Disconnected": "מכשיר מנותק",
    "No Events Available": "אין התרעות זמינות",
    Found: "נמצאו",
    of: "מתוך",
    entries: "רשומות",
    "Power Consumption": "צריכת חשמל",
    Yesterday: "אתמול",
    "Month Earlier": "חודש קודם",
    "Year Earlier": "שנה קודמת",
    Custom: "התאמה אישית",
    "Pick Group": "בחר קבוצה",
    Monthly: "חודשית",
    Daily: "יומית",
    "Faults Report": 'דו"ח תקלות',
    Faults: 'דו"ח תקלות',
    fault: "תקלה",
    name: "שם",
    power: "עוצמה",
    sys___connected: "מצב זמינות",
    rssi: "עוצמת קליטה",
    "Commissioned & Scanned": "מכשירים שהותקנו ונסרקו",
    "Commissioned & Scanned Devices": "מכשירים שהותקנו ונסרקו",
    "Commissioned & Unscanned Devices": "מכשירים שהותקנו ולא נסרקו",
    "Success Commission": "הותקנו בהצלחה",
    "Commissioned & Unscanned": "הותקנו ולא נסרקו",
    "You must insert both from & to dates": "עליך להכניס טווח תאריכים תקין",

    OK: "תקין",
    Yes: "כן",
    No: "לא",
    Success: "הצלחה",
    Error: "שגיאה",
    "Are you sure you want to move device": "האם אתה בטוח שברצונך להזיז את",
    Control: "שליטה",

    Data: "מידע",
    Power: "עוצמה",
    Parent: "הורה",
    "Connected fixtures": "בקרים מחוברים",
    Online: "מחובר",
    Offline: "מנותק",
    Connected: "מחובר",
    Disconnected: "מנותק",
    connected: "מחובר",
    disconnected: "מנותק",
    "Other Faults": "תקלות אחרות",
    Description: "תיאור",
    description: "תיאור",
    "Default ON": "דולק בברירת מחדל",
    "Manual Control": "שליטה ידנית",
    Active: " פעיל",
    "device.controls property is updated": "device.controls-ההגדרות נשמרו ב",
    "device.schedule property is updated": "device.schedule-ההגדרות נשמרו ב",
    ON: "הדלק",
    OFF: "כבה",
    "Scheduled OFF": "תזמון כבוי",
    "Scheduled ON": "תזמון דולק",
    All: "הכל",
    Resolved: "פתור",
    Open: "פתוח",
    "User Management": "ניהול משתמשים",
    "user management": "ניהול משתמשים",
    Configurations: "הגדרות",
    "Management tools": "כלי ניהול",
    Unassign: "בטל שיוך",
    Name: "שם",
    Email: 'דוא"ל',
    // Role: 'רמת שליטה',
    Actions: "פעולות",
    State: "מצב",
    Hour: "שעה",
    Day: "יום",
    Month: "חודש",
    Year: "שנה",
    "Coming soon!": "בקרוב!",
    "Select District": "בחר רובע",
    "Select Cabinet": "בחר מרכזיה",
    "Create new group": "צור קבוצה חדשה",
    "Assigned devices": "מכשירים משוייכים",
    "Assigned Devices": "מכשירים משוייכים",
    "Group Name": "שם קבוצה",
    "Group name": "שם קבוצה",
    "Clear selected markers": "נקה סימונים נבחרים",
    "Unnamed device": "מכשיר ללא שם",
    Identity: "זהות",
    Category: "קטגוריה",
    "Delete current view": "מחק תצוגה נוכחית",
    "Do you want to delete this filter view?":
      "האם אתה מעוניין למחוק את תצוגת הסינון?",
    "Filter Settings": "הגדרות סינון",
    "Device type": "סוג האוביקט",
    "Device location successfully updated": "מיקום המכשיר עודכן בהצלחה",
    "Successfully updated Contacts!": "מקבלי הדוח עודכנו בהצלחה",
    Type: "סוג",
    type: "סוג",
    Found: "נמצאו",
    Items: "פריטים",
    Zoom: "קרב",
    "Use current": "השתמש בנוכחי",
    "Show all objects": "הראה את כל האוביקטים",
    "Device Class": "סיווג מכשיר",
    "Status Filters": "פילטר סטטוסים",
    "Create New Filter": "צור פילטר חדש",
    Create: "צור",
    "Filter Name": "שם הסנן",
    "Add new project": "הוסף פרויקט חדש",
    "Create project": "צור פרויקט",
    "Register user": "רשום משתמש חדש",
    "Create new user": "צור משתמש חדש",
    Password: "סיסמא",
    password: "סיסמא",
    "Repeat password": "חזור על סיסמא",
    "Passwords are not equals": "הסיסמאות אינן זהות",
    "Project Properties": "הגדרות פרויקט",
    PROJECT: "פרויקט",
    Project: "פרויקט",
    VIEW: "תצוגה",
    "Add property": "הוסף הגדרה",
    Projects: "פרויקטים",
    "Management Tools": "כלי ניהול",
    "Refresh reporting data": "רענן נתוני דיווח",
    "Refresh now": "רענן עכשיו",
    "Project Users": "משתמשי פרויקט",
    "Assign user": "שייך משתמש",
    "Company Users roles": "תפקידי משתמשי חברה",
    Edit: "ערוך",
    ID: "מספר מזהה",
    "Project user": "משתמש פרויקט",
    "Project admin": "מנהל פרויקט",
    "Company user": "משתמש חברה",
    "Company admin": "מנהל חברה",
    "New Schedule": "תזמון חדש",
    "Scheduled Groups": "קבוצות מתוזמנות",
    // curves
    "The device operates according to cabinet schedule": "היחידה פועלת לפי תזמון של מרכזיה",
    "No Schedule has been set": "לא הוגדר תזמון",
    "The device operates according to astronomical clock": "היחידה פועלת לפי שעון אסטרונומי",
    "Light will turn on at Dusk, and will turn off at Dawn":
      "האור יידלק בבין ערביים, ויכבה בשחר",
    "Curve Deleted Successfully": "הגרף נמחק בהצלחה",
    "Curve Updated Successfully": "הגרף נשמר בהצלחה",
    "To start, select which groups to schedule - at least 1 group must be selected":
      "כדי להתחיל, בחר קבוצות עליהן יחול התזמון, לפחות קבוצה אחת חייבת להיבחר",
    "There are no groups in this project. You need to create one for creating schedule":
      "לא קיימות קבוצות בפרויקט. עליך ליצור אחת על מנת ליצור תזמון",
    "Select All": "בחר הכל",
    "Available Dimming Сurve": "גרף עמעום זמין",
    "No curves yet. Please, create new": "גרף עמעום אינו קיים. אנא צור חדש",
    "Add to schedule": "הוסף לתזמון",
    "Add curve and dates to the schedule": "הוסף גרף מועדים לתזמון",
    "Offset In Minutes": "הזזה בדקות",
    Curve: "גרף",
    Sunday: "ראשון",
    Monday: "שני",
    Tuesday: "שלישי",
    Wednesday: "רביעי",
    Thursday: "חמישי",
    Friday: "שישי",
    Saturday: "שבת",
    "Are you sure you want to delete this dimming curve? Delete will affect schedule":
      "האם אתה בטוח שברצונך למחוק את הגרף? מחיקה תשפיע על התזמון",
    Color: "צבע",
    January: "ינואר",
    February: "פברואר",
    March: "מרץ",
    April: "אפריל",
    May: "מאי",
    June: "יוני",
    July: "יולי",
    August: "אוגוסט",
    September: "ספטמבר",
    October: "אוקטובר",
    November: "נובמבר",
    December: "דצמבר",
    "Add New Schedule": "הוסף תזמון חדש",
    "Are you sure you want to delete this schedule?":
      "אתה בטוח שברצונך למחוק את התזמון הזה?",
    "Forgot password?": "?שכחת סיסמא",
    Username: "שם משתמש",
    Reports: "דוחות",
    "Runtime Report": 'דו"ח זמן פעילות',
    Runtime: 'דו"ח זמן פעילות',
    "Bulk Uploader": "העלאת מכשירים",
    "Bulk Asset Uploader": "העלאת מכשירים וירטואליים",
    Upload: "העלאה",
    Preview: "תצוגה מקדימה",
    "Select a CSV file": "בחר קובץ CSV",
    "This is not a csv file": "בבקשה בחר קובץ CSV",
    "Device is not created": "המכשיר לא נוצר",
    "Filter Report": "סינון דוח",
    Expression: "ביטוי",
    "Pick Date": "בחר תאריך",
    "Device ID": "שם המכשיר",
    "Cabinet Number": "מספר מרכזיה",
    "Daily run time": "זמן פעילות יומי",
    "Standard Report": "דוח רגיל",
    "Extended Status Report": "דוח מורחב",
    "All devices": "כל המכשירים",
    "Project name": "שם פרויקט",
    "Active devices": "מכשירים פעילים",
    "Manual change will be valid until the next scheduled change":
      "השינוי הידני תקף עד לשינוי המתוזמן הבא",
    "Resets at next schedule change": "מתאפס בשינוי תזמון הבא",
    "Change Dimming": "שנה רמת עמעום",
    "Current State": "מצב נוכחי",
    "Scheduled State": "מצב מתוזמן",
    "Total devices in the project": 'סה"כ מכשירים בפרויקט',
    "With alerts": "עם התרעות",
    Uncommissioned: "לא מותקן",
    "Event devices": "אירועי מכשירים",
    "Number of devices": "מספר מכשירים",
    "Device with Open status": "מכשיר עם סטטוס פתוח",
    "Device with Resolved status": "מכשירים עם סטטוס סגור",
    "Search address": "חפש כתובת",
    "Group Overview": "סקירה כללית לקבוצה",
    "Group overview": "סקירה כללית לקבוצה",
    "Add short description": "הוסף תיאור קצר",
    "Name the group": "הקצה שם לקבוצה",
    "Save group": "שמור קבוצה",
    "Device Search": "חיפוש מכשיר",
    "Multiple Search": "חיפוש מרובה",
    "There are no available fields to search": "לא קיימים ערכים זמינים לחיפוש",
    "Type Value": "הכנס ערך",
    "Type Number": "הכנס מספר",
    "Select Field": "בחר שדה",
    "Select Device Type": "בחר סוג מכשיר",
    "Show Installed Last Week": "הצג מכשירים שהותקנו בשבוע האחרון",
    "Search by": "חפש לפי",
    "Search Value": "בחר ערך",
    "Choose Field": "בחר שדה",
    None: "ללא",
    Export: "יצא",
    "Devices with open events": " מכשירים עם אירועים פתוחים",
    "Event devices": "מכשירים עם ארוע",
    "Edit group": "ערוך קבוצה",
    "Dim level": "רמת עמעום",
    "Map language": "שפת מפה",
    "Map Style": "סגנון מפה",
    "Select Project": "בחר פרויקט",
    "Status Report": 'דו"ח סטטוס',
    Status: "סטטוס",
    "Connection Status": "מצב חיבור",
    "Active Status": "מצב פעיל",
    since: "מאז",
    "Creation Date": "תאריך יצירה",
    "Group Management": "ניהול קבוצות",
    "Devices in current filter": "מכשירים בפילטר הנוכחי",
    "Offline devices": "מכשירים מנותקים",
    "Number of alerts in devices": "מספר התרעות במכשירים",
    Alerts: "התרעות",
    ALERTS: "התרעות",
    "Live power reading": "רמת מתח בזמן אמת",
    "No data available": "אין מידע זמין",
    "No Data Available": "אין מידע זמין",
    "Choose fixtures": "בחר בקרים",
    "Edit Group": "ערוך קבוצה",
    "Schedules Management": "ניהול תזמונים",
    "Drag and drop to change priority": "גרור ושחרר לשינוי עדיפות",
    Priority: "עדיפות",
    "Active?": "פעיל?",
    "Create Schedule": "צור תזמון",
    "Update Schedule": "ערוך תזמון",
    "Create New Schedule": "צור תזמון חדש",
    "Curve Management": "ניהול הגרף",
    "Select specific days throughout the year": "בחר בימים רצויים במהלך השנה",
    "Save your Schedule": "שמור תזמון",
    "Schedule Name": "שם התזמון",
    "Short Description": "תאור קצר",
    "Create New Curve": "צור גרף חדש",
    "Select Days": "בחר ימים",
    Delete: "מחק",
    "Yes, delete": "כן, מחק",
    "On Time": "זמן הדלקה",
    "Off Time": "זמן כיבוי",
    "Please note that deleting an active curve can affect schedules that are using it":
      "שים לב כי מחיקת גרף פעיל עלול להשפיע על תזמונים המשתמשים בו",
    "Curve Details": "נתוני הגרף",
    Start: "התחלה",
    End: "סיום",
    "Dimming Curve Name": "שם גרף העמעום",
    "Short Description": "תיאור קצר",
    "offset (minutes)": "הסט (דקות)",
    Sunrise: "זריחה",
    Sunset: "שקיעה",
    Dusk: "בין ערביים",
    Dawn: "שחר",
    None: "ללא",
    "Choose a color for this setting:": "בחר צבע להגדרה זו",
    "Note:": "הערה",
    "Dusk Dawn Power": "עוצמת דימור עבור בין ערביים/שחר",
    "Sun time is in": "זמני השמש מוגדרים לפי",
    "Select timezone": "אזור זמן",
    "Dim Level": "רמת עמעום",
    "Message Sent": "ההודעה נשלחה",
    "You can control dimming level of dusk and dawn in power field": `תוכל לשלוט על עוצמת הדימור בשדה עוצמה`,
    "Current Filter:": "פילטר נוכחי",
    "Create New Group": "צור קבוצה חדשה",
    "Save Group": "שמור קבוצה",
    "Add User": "הוסף משתמש",
    "Add New User": "הוסף משתמש חדש",
    Add: "הוסף",
    //map legend
    LEGEND: "מקרא",
    On: "זמן הדלקה",
    "Offline, last set to On": "מנותק - מצב אחרון: דולק",
    Off: "זמן כיבוי",
    "Offline, last set to Off": "מנותק - מצב אחרון: כבוי",
    "Video On": "וידאו פעיל",
    "Video Off": "וידאו לא פעיל",
    "Sensor On": "חיישן פעיל",
    "Sensor Off": "חיישן לא פעיל",
    "Regular Cabinet": "מרכזיה רגילה",
    "Virtual Cabinet": "מרכזיה וירטואלית",
    "Regular Fixture": "בקר רגיל",
    "Virtual Fixture": "בקר וירטואלי",
    "Underground Fixture": "בקר תת קרקעי",
    Underground: "תת קרקעי",
    "Highmast Fixture": "בקר שושנה",
    "Security Fixture": "בקר אבטחה",
    Security: "אבטחה",
    "Partial Communication": "תקשורת חלקית",
    "Electrical Problem": "בעיה חשמלית",
    "Electrical Fault": "בעיה חשמלית",
    "Electrical fault": "בעיה חשמלית",
    "Lack Of Voltage": "חוסר מתח",
    "Burnt Lamp": "מנורה שרופה",
    Asset: "מכשיר וירטואלי",
    "On/Off": "דולק/כבוי",
    Video: "וידאו",
    Sensor: "חיישן",
    Cabinet: "מרכזיה",
    Maintenance: "תחזוקה",
    VIDEO: "וידאו",

    //reports search
    Field: "שדה",
    "By expression": "ביטוי",
    Clear: "נקה",
    Apply: "החל",
    Logout: "התנתק",
    //alerts tab
    "OK devices": "מכשירים פעילים",
    "Alert devices": "מכשירים עם התרעות",
    "Total number of alerts": 'סה"כ התרעות',
    "Open alerts": "התרעות פתוחות",
    "Resolved alerts": "התרעות סגורות",
    "Environmental Failure": "מכשול סביבתי",
    "Device Failure": "כשל במכשיר",
    "Unknown Problem": "בעיה לא ידועה",
    Total: "סך הכל",
    //curves new update
    minutes: "",
    Offset: "הזז",
    "Default style changed": "סגנון מפה שונה",

    //manager options
    "Open Manager Options": "פתח אפשרויות מנהל",
    "Manager Login": "התחברות מנהל",
    "Password*": "סיסמא",
    "Password is required": "הקלד סיסמא",
    "Password is not valid": "סיסמא שגויה",
    Login: "התחבר",
    "Manager Options": "אפשרויות מנהל",
    "Commission Monitoring": "ניטור התקנה",
    "Light Control Monitoring": "ניטור תאורה",
    "Commissioned & Updated Today": "מכשירים שעודכנו היום והותקנו",
    "Uncommissioned & Updated Today": "מכשירים שעודכנו היום ולא הותקנו",
    "Uncommissioned Without Location": "מכשירים שלא הותקנו וללא מיקום",

    "Device ID": "מספר מכשיר",
    "Time Of Commission": "תאריך התקנה",
    Commissioner: "שם המתקין",
    "Lat/Lng": "מיקום",

    //advanced
    Device: "מכשיר",
    "Device Status": "סטטוס מכשיר",
    "Change Device Status": "שנה את סטטוס המכשיר",
    "Changing device mode manually": "שינוי מצב המכשיר באופן ידני",
    "Go To": "עבור אל",
    "Choose Mode": "בחר מצב",
    Change: "שנה",
    "Group Status": "סטטוס קבוצה",
    "Change Group Status": "שנה את סטטוס הקבוצה",
    "Changing group mode manually": "שינוי מצב הקבוצה באופן ידני",
    "Group ID": "מספר קבוצה",
    Connected: "מחובר",
    "Fault In Care": "תקול בטיפול",
    Maintenance: "בתחזוקה",
    "You must choose at least one item in table":
      "עליך לבחור לפחות פריט אחד מהרשימה",
    Voltage: "מתח",
    Current: "זרם",
    "Power Factor": "מקדם הספק",
    Watch: "צפה",
    Regions: "איזורים",
    "Active devices": "מכשירים פעילים",
    "Not active": "לא פעילים",
    Maintenance: "תחזוקה",
    "High priority": "חשיבות גבוהה",
    "Medium priority": "חשיבות בינונית",
    "Low priority": "חשיבות נמוכה",
    "Installations in the past month": "התקנות בחודש האחרון",
    High: "גבוהה",
    Medium: "בינונית",
    Low: "נמוכה",
    Info: "מידע",
    "High Priority": "עדיפות גבוהה",
    "Medium Priority": "עדיפות בינונית",
    "Low Priority": "עדיפות נמוכה",
    "Date Time": "תאריך ושעה",
    Priority: "חשיבות",
    Description: "תיאור",
    Id: "מזהה",
    "Pole type": "סוג עמוד",
    "Painted pole": "עמוד צבוע",
    "Screw type": "סוג בורג",
    "Screw Type": "סוג בורג",
    "Pole Color": "צבע עמוד",
    "Number Of Lighting Fixture": "מספר גופי תאורה",
    "Estimated Address": "כתובת משוערת",
    "Power distribution": "הספק גופי תאורה",
    "Total powered": 'סה"כ ',
    "Total screws": 'סה"כ ברגים',
    "Total painted": 'סה"כ צבועים',
    "Total poles": 'סה"כ עמודים',
    Regular: "רגיל",
    Fragile: "שביר",
    Unspecified: "לא מוגדר",
    Painted: "צבוע",
    "Not painted": "לא צבוע",
    Urgent: "דחוף",
    "Are you sure you want to delete {{username}}?":
      "האם אתה בטוח שברצונך למחוק את {{username}}?",
    "Delete User": "מחק משתמש",
    "First name": "שם פרטי",
    "Last name": "שם משפחה",
    "Phone number": "טלפון",
    "Phone Number": "טלפון",
    "Example": "לדוג'",
    Role: "תפקיד",
    Restricted: "משתמש מוגבל",
    "Edit User": "ערוך משתמש",
    "Full Name": "שם מלא",
    "Project User": "משתמש פרויקט",
    "Company Admin": "אדמין חברה",
    "Company User - Read Only": "אדמין חברה - צפיה בלבד",
    "Project User - Read Only": "משתמש פרויקט - צפיה בלבד",
    "Project User - Read only": "משתמש פרויקט - צפיה בלבד",
    Regions: "איזורים",
    "Device State": "מצב בקרים",
    "Active devices": "מכשירים פעילים",
    Maintenance: "תחזוקה",
    "High priority": "חשיבות גבוהה",
    "Medium priority": "חשיבות בינונית",
    "Low priority": "חשיבות נמוכה",
    Motion: "תנועה",
    MOTION: "תנועה",
    RADAR: "רדאר",
    Radar: "רדאר",
    High: "גבוהה",
    Medium: "בינונית",
    Low: "נמוכה",
    "Date Time": "תאריך ושעה",
    Priority: "חשיבות",
    Description: "תיאור",
    Id: "מזהה",
    "Pole type": "סוג עמוד",
    "Painted pole": "עמוד צבוע",
    "Power distribution": "הספק גופי תאורה",
    "Total powered": 'סה"כ ',
    "Total screws": 'סה"כ ברגים',
    "Total painted": 'סה"כ צבועים',
    "Total poles": 'סה"כ עמודים',
    Regular: "רגיל",
    Fragile: "שביר",
    Unspecified: "לא מוגדר",
    Painted: "צבוע",
    "All Projects": "כל הפרויקטים",
    "Not painted": "לא צבוע",
    Aluminum: "אלומיניום",
    Connie: "קוני",
    Octagon: "מתומן",
    Watt: "וואט",
    "Other value": "ערך אחר",
    "NATI South": 'נת"י דרום',
    "NATI North East": 'נת"י צפון מזרח',
    "NATI Central - South": 'נת"י מרכז - דרום',
    "NATI Juda - Samaria ": 'נת"י יהודה ושומרון',
    "NATI Central - North": 'נת"י מרכז - צפון',
    "NATI North West": 'נת"י צפון מערב',
    South: "דרום",
    "North East": "צפון מזרח",
    "Central - South": "מרכז - דרום",
    "Juda - Samaria ": "יהודה ושומרון",
    "Central - North": "מרכז - צפון",
    "North West": "צפון מערב",
    "Confirm Password": "אימות סיסמא",
    "The password must contain 1 capital letter, 1 special character, 1 number": "הסיסמא חייבת להכיל אות גדולה, תו מיוחד ומספר",
  
    // upload-excel view
    "Select Excel Type": "בחר סוג אקסל",
    "Set Device Data By Pole Num": "עדכון מידע מכשיר לפי מספר עמוד",
    "Set Part Num By Device Id": "עדכון מס' קטלוגי לפי מספר מזהה",
    "Set Pole Num By Hardware Id": "עדכון מספר עמוד לפי מזהה חומרה",
    "Upload Excel By Unit": "טעינת אקסל לפי מספר קטלוגי",
    "Upload Excel By Barcode": "טעינת אקסל לפי ברקוד",
    "Upload Excel": "טעינת קובץ אקסל",
    "Upload Excel File": "העלאת קובץ אקסל",
    "Set column as Pole ID": "הגדר עמודה בתור מספר עמוד",
    "Mark columns titles fields - First, set Pole ID by pressing the Set column as Pole ID text":
      "סמן את כותרת העמודה - ראשית, סמן מספר עמוד",
    "Release column": "שחרר עמודה",
    "Save Values": "שמור ערכים",
    "Close alert": "סגור התרעה",
    "Confirm and Continue": "אשר והמשך",
    "The file has been received successfully": "הקובץ נקלט בהצלחה.",
    "Download Log File": "שמור קובץ תוצאות",
    "Please choose the tab you would like to upload first":
      "נא בחר את הגיליון אותו תרצה להעלות ראשון",
    "Are you sure that you want to save the uploaded Excel file to the system?":
      "האם אתה בטוח שברצונך לשמור את הטבלה במערכת?",
    "Click the white box and set the columns according to their fields in the system":
      "לחץ על התיבה הלבנה והגדר את שאר העמודות לפי הסוג שלהן מתוך הרשימה הנפתחת",
    "Are you sure that you want to mark this column as Pole ID? a mistake will cause errors because this is a main indication":
      "האם אתה בטוח שברצונה לסמן עמודה זו כמספר עמוד? טעות תגרום לשיבוש היות וזה אינדקס ראשי",
    "Error choosing worksheet. Please check the file or decrease its size by separating sheets and try again.":
      "אירעה שגיאה בבחירת הגליון. נא בדוק את הקובץ או צמצם את גודלו על ידי צמצום מספר הגליונות ונסה שוב",
    "Please choose the column of Pole ID to function as the main column":
      "נא בחר את העמודה של הערך Pole ID שתוגדר בתור העמודה הראשית במסמך.",
    "Phase issue": "בעיות פאזה",
    "Circuit issue": "בעיות מעגל חשמלי",
    AFFECTED: "מכשירים שהושפעו",
    "SENSOR EVENTS": "אירועי חיישנים",
    "Door still open": "דלת שנותרה פתוחה",
    "Cabinet humidity is over 80%": "לחות מרכזיה גבוהה מ80%",
    "Project Sunset Time": "זמן השקיעה בפרויקט",
    "Project Sunrise Time": "זמן הזריחה בפרויקט",
    "NEXT OPERATION": "הפעלה הבאה",
    Events: "אירועים",
    Operation: "הפעלה",
    "Services": "שירותים",
    "Cabinet Name/ID": "שם מרכזיה/מספר",
    "Cabinet ID": "מספר מרכזיה",
    Monitor: "בקרה",
    "Cabinet Details": "פרטי מרכזיה",
    "Cabinet name": "שם המרכזיה",
    "Road number": "מספר כביש",
    "Technical Information": "נתונים טכניים",
    "Communication Status": "סטטוס תקשורת",
    Phases: "פאזות",
    "Connector size": "גודל חיבור",
    Connector_Size: "גודל חיבור",
    Geolocation: "מיקום גאוגרפי",
    "Last Activity": "פעילות אחרונה",
    "Active Since": "פעיל מאז",
    "Inactive Since": "לא פעיל מאז",
    "Cabinet name (number)": "שם מרכזיה (מספר)",
    "Comm Status": "סטטוס תקשורת",
    Filter: "סינון",
    Values: "ערכים",
    "Cabinet information": "פרטי מרכזיה",
    "First top priority alert date": "תאריך ההתרעה הכי הדחופה",
    "First Top Priority Event Time": "תאריך האירוע הכי דחוף",
    "Cabinet data": "מידע מרכזיה",
    "Cabinet Data": "מידע מרכזיה",
    "Device Data": "מידע על המכשיר",
    "Device data": "מידע על המכשיר",
    "Alert description": "תיאור התרעה",
    "Alert Description": "תיאור התרעה",
    "Event Description": "תיאור אירוע",
    "Cabinet Information": "פרטי מרכזיה",
    "from cabinet": " מהמרכזיה ",
    "Project Name": "שם הפרויקט",
    "3 in a row": "3 יח' רצופות מנותקות",
    "LED Fault": "תקלת LED",
    "Alert details": "פרטי התרעה",
    "Date time": "תאריך",
    Severity: "חומרה",
    Comments: "תגובות",
    "Device Location": "מיקום מכשיר",
    "Ticket Id": "מזהה כרטיס",
    "Incident Details": "פרטי התרעה",
    Highmast: "שושנה",
    "Filter Devices": "סינון בקרים",
    "Devices Data": "מידע בקרים",
    "Filtered devices": "בקרים מסוננים",
    "Overall devices": 'סה"כ בקרים',
    "All Devices": "כל הבקרים",
    "Devices With Alerts": "בקרים עם התרעות",
    "Devices With Events": "בקרים עם אירועים",
    "Supplied by pole": "עמוד מזין",
    "Supplied By Pole": "עמוד מזין",
    "Cabinet information": "פרטי מרכזיה",
    "First top priority alert date": "תאריך ההתרעה הכי דחופה",
    "First Top Priority Event Time": "תאריך האירוע הכי דחוף",
    "Cabinet data": "מידע מרכזיה",
    "Cabinet Data": "מידע מרכזיה",
    "Device Data": "מידע על המכשיר",
    "Device data": "מידע על המכשיר",
    "Event Description": "תיאור אירוע",
    "Cabinet Information": "פרטי מרכזיה",
    "from cabinet": " מהמרכזיה ",
    "Project Name": "שם הפרויקט",
    "3 in a row": "שלוש ברצף",
    "Date time": "תאריך",
    Copper: "נחושת",
    Created: "נוצר",
    "Device Location": "מיקום מכשיר",
    "Ticket Id": "מזהה כרטיס",
    Highmask: "שושנה",
    Crown: "כתר",
    "RESET PASSWORD": "אפס סיסמא",
    "Enter Email Address": "הכנס כתובת אימייל",
    "Enter SMS Code": "הכנס קוד שהתקבל בהודעה",
    "Enter Email Code": "הכנס קוד שהתקבל באימייל",
    "Enter Authenticator Code": "הכנס קוד מאפליקציית Authenticator",
    "Enter MFA Code": "הכנס קוד MFA",
    "Enter New Password": "הכנס סיסמא חדשה",
    "Password Confirmation": "אימות סיסמא",
    "Save New Password": "שמור סיסמא חדשה",
    "Set New Password": "הגדר סיסמא חדשה",
    "Overall filtered devices": 'סה"כ בקרים מפולטרים',
    "Overall devices": 'סה"כ בקרים',
    "Devices filter by alerts": "סינון בקרים לפי התרעות",
    "Devices filter by events": "סינון בקרים לפי אירועים",
    "All Devices": "כל הבקרים",
    "Date time": "תאריך",
    "Close Event": "סגור אירוע",
    "Close event": "סגור אירוע",
    "First name must have at least 3 characters":
      "שם פרטי חייב להיות באורך 3 תווים לפחות",
    "Last name must have at least 3 characters":
      "שם משפחה חייב להיות באורך 3 תווים לפחות",
    "Email format invalid": "כתובת האימייל אינה תקינה",
    "Phone number format invalid": "מס' הטלפון אינו תקין",
    "No phone number": "לא קיים מספר טלפון",
    "Phone number is too short": "מס' הטלפון קצר מידיי",
    "Phone number must include only digits and intl": "מס' הטלפון חייב להכיל מספרים וקידומת בינלאומית בלבד",
    "The password and the confirm password does not match":
      "סיסמא ואימות סיסמא אינם תואמים",
    "Assign user to a project": "עליך לשייך משתמש זה לפרויקט",
    "You need to specify a role": "עליך לבחור תפקיד",
    "An email with reset password information was sent to ":
      "אימייל עם מידע על שחזור סיסמא נשלח אל ",
    " Please check your mailbox and follow the email procedure":
      " בבקשה תבדוק את האימייל שלך והמשך את התהליך הרשום שם ",
    "Invalid mfa code": "קוד אימות רב שלבי שגוי",
    "MFA method": "שיטת MFA",
    "Cabinet controller down": "פקד מרכזיה מנותק",
    "Cabinet Controller Down": "פקד מרכזיה מנותק",
    "Cabinet down": "מרכזיה מנותקת",
    "Cabinet Down": "מרכזיה מנותקת",
    "Single Unit": "יחידה בודדת",
    "partial_control": "בקרה חלקית",
    "partial control": "בקרה חלקית",
    "Partial Control": "בקרה חלקית",
    "electrical_fault": "בעיה חשמלית",
    "electrical fault": "בעיה חשמלית",
    "disconnected_pole": "עמוד מנותק",
    "disconnected pole": "עמוד מנותק",
    "Disconnected Pole": "עמוד מנותק",
    "daytime_light_fault": "תקלת פנס דולק ביום",
    "Daytime Light Fault": "תקלת פנס דולק ביום",
    "Probability": "סבירות",
    "communication_fault": "תקלת תקשורת",
    "Communication Fault": "תקלת תקשורת",
    "installation": "התקנה",
    "Installation": "התקנה",
    "Cabinet Power Consumption Greater Than Expected": "צריכת החשמל של המרכזיה גבוהה מהצפוי",
    "Daylight Power Consumption Is Greater Than Expected": "צריכת החשמל ביום גבוהה מהצפוי",
    "Power Supply Failure": "כשל באספקת החשמל",
    "Ups Battery Failure": "סוללת UPS תקולה",
    "5% From Cabinet": "5% ממרכזיה",
    "5% from cabinet": "5% ממרכזיה",
    "At Least 5% Devices Disconnected": "לפחות 5% מהמכשירים מנותקים",
    "at least 5% devices disconnected": "לפחות 5% מהמכשירים מנותקים",
    "At Least 85% Devices Disconnected": "לפחות 85% מהמכשירים מנותקים",
    "at least 85% devices disconnected": "לפחות 85% מהמכשירים מנותקים",
    "Circuit down": "מעגל מנותק",
    "Circuit Down": "מעגל מנותק",
    "Cabinet Power Consumption greater than expected": "צריכת חשמל במרכזיה גדולה מהצפוי",
    "Daylight Power Consumption is greater than expected": "צריכת החשמל ביום גדולה מהצפוי",
    "Consumption anomalies": "השוואת צריכה",
    "Abnormal data": "נתונים חריגים",
    "Consumption during the day": "צריכה במהלך היום",
    "Under Voltage": "מתח נמוך",
    "Over Voltage": "מתח גבוה",
    "Percentage Deviation": "חריגה באחוזים",
    "Actual Consumption": "צריכה בפועל",
    "Expected Consumption": "צריכה מקסימלית צפויה",
    Overall: 'סה"כ',
    "Phase R": "פאזה R",
    "Phase S": "פאזה S",
    "Phase T": "פאזה T",
    "Unusual Measure": "מדד חריג",
    "Pick data": "בחר מידע",
    Last: "אחרון",
    "Pick time": "בחר זמן",
    "Show data": "הראה מידע",
    "Show last data": "הראה מידע אחרון",
    Demands: "איכות",
    "Showing data collected at": "מראה מידע שנאסף ב",
    "Fixture Photometry": "פוטומטריית המכשיר",
    Harmonics: "הרמוניות",
    Phasor: "פאזור",
    Magnitude: "משרעת",
    Angle: "זווית",
    "Voltage Harmonics Magnitude": "הרמוניות מתח",
    "Current Harmonics Magnitude": "הרמוניות זרם",
    Frequency: "תדירות",
    Dates: "תאריכים",
    Commissioners: "מתקינים",
    all: "כולם",
    "Installed Fixtures": "בקרים מותקנים",
    "Connected Fixtures": "בקרים מחוברים",
    "Disconnected Fixtures": "בקרים מנותקים",
    "Installation Monitoring Report": "דוח ניהול התקנות",
    of: "מתוך",
    "Rows per page": "שורות לדף",
    "Select Commissioners": "בחר מתקינים",
    "Report by Cabinet": "דוח לפי מרכזיה",
    "Report by Dates": "דוח לפי תאריכים",
    "Report by Commissioner": "דוח לפי מתקין",
    "Report by Multiple fields": "בחירה מרובה",
    "Report by Installer": "דוח לפי מתקין",
    "Generate Monitoring Report": "צור דוח ניהול",
    "Select Cabinets": "בחר מרכזיות",
    "Select Dates": "בחר תאריכים",
    "Select Fields": "בחר שדות",
    " to ": " עד ",
    "SHOW ALL": "הראה הכל",
    "Change Commissioners": "שנה מתקינים",
    "Change Cabinets": "שנה מרכזיות",
    "Change Dates": "שנה תאריכים",
    "Installation Monitoring": "ניהול התקנות",
    all: "כולם",
    "Pick country": "בחר מדינה",
    "Pick timezone": "בחר איזור זמן",
    "Periodic Reports": "דוחות תקופתיים",
    "Project Groups": "קבוצות פרויקט",
    "Project Schedules": "תזמונים פרויקט",
    "Project Events": "אירועים פרויקט",
    "Project Alerts": "התרעות פרויקט",
    "Project Cabinets": "מרכזיות פרויקט",
    "Virtual Devices": "מכשירים וירטואליים",
    "Fixture Name": "שם יחידה",
    "Add Virtual Fixture": "הוסף יחידה וירטואלית",
    longitude: "אורך",
    "Select Period": "בחר תקופה",
    "Select cabinets to continue": "בחר מרכזיות כדי להמשיך",
    week: "שבוע",
    month: "חודש",
    year: "שנה",
    "Select period to continue": "בחר תקופה כדי להמשיך",
    "Select Installer": "בחר מתקין",
    "Select Installers": "בחר מתקינים",
    "Select installers to continue": "בחר מתקינים כדי להמשיך",
    "Select Tables Sorting Method": "מיון טבלאות לפי",
    installer: "מתקין",
    Installer: "מתקין",
    "Export All devices": "יצוא כלל המכשירים",
    "Export Disconnected devices": "יצוא מכשירים מנותקים",
    "Open Map": "פתח מפה",
    "Close Map": "סגור מפה",
    Default: "ברירת מחדל",
    "GPS location": "מיקום GPS",
    "GIS location": "מיקום GIS",
    "APP location": "מיקום APP",
    "Manual location": "מיקום ידני",
    "No data available for this selection": "אין מידע זמין לבחירה זו",
    "Select Sorting Method": "בחר דרך מיון",
    "Select table sorting method to continue":
      "בחר דרך חלוקת טבלאות כדי להמשיך",
    "Press enter to select": "לחץ אנטר לבחירה",
    "Press enter to remove": "לחץ אנטר להורדה",
    "Add Virtual Device": "הוסף מכשיר וירטואלי",
    "Edit Virtual Device": "ערוך מכשיר וירטואלי",
    "Edit Device": "ערוך מכשיר",
    "Add Devices": "הוסף מכשירים",
    "report for dates": "דוח לתאריכים",
    "Are you sure you want to delete this device":
      "האם אתה בטוח שתרצה למחוק את המכשיר",
    "Delete Virtual Device": "מחק מכשיר וירטואלי",
    "Clear Selection": "נקה בחירה",
    total_poles: "סך הכל עמודים",
    total_fixtures: "סך הכל מכשירים",
    Fixtures: "מכשירים",
    Poles: "עמודים",
    Controlled: "מבוקרים",
    "Actual controlled": "מבוקרים בפועל",
    "Never": "מעולם לא תקשרו",
    "Total Pole": "סך הכל עמודים",
    "Total Fixture": "סך הכל מכשירים",
    "Add Row": "הוסף שורה",
    "Select Reports": "בחר דוחות",
    "Periodic reports for project": "דוחות תקופתיים עבור פרויקט",
    "Save Changes": "שמור שינויים",
    "Edit Row": "ערוך שורה",
    "You must pick reports to continue": "חובה לבחור דוחות בכדי להמשיך",
    "Config Location & View": "הגדרת מיקום ותצוגה",
    "Add Report Recipient": "הוסף מקבל דוח",
    "Edit Report Recipient": "ערוך מקבל דוח",
    "There are no report recipient for this project":
      "אין מקבלי דוחות עבור פרויקט זה",
    "Delete Report Recipient": "מחק מקבל דוח",
    "Are you sure you want to delete this report recipient":
      "האם אתה בטוח שתרצה למחוק את מקבל הדוח",
    daily: "יומי",
    weekly: "שבועי",
    monthly: "חודשי",
    yearly: "שנתי",
    "Installation Monitoring Report": "דוח ניהול התקנות",
    "Attached Files": "קבצים מצורפים",
    "Open Cabinet Page": "פתח דף מרכזיה",
    "Add New File": "הוסף קובץ חדש",
    Value: "ערך",
    Fields: "שדות",
    "Edit Property": "ערוך",
    "Pick input type": "בחר סוג קלט",
    Logo: "לוגו",
    "File Name": "שם קובץ",
    "File Type": "סוג הקובץ",
    "Add File": "הוסף קובץ",
    "Delete File": "מחק קובץ",
    "Edit File": "ערוך קובץ",
    "View File": "צפה בקובץ",
    "Project Timezone": "איזור הזמן של הפרויקט",
    "Project Location Latitude": "קו הרוחב של הפרויקט",
    "Project Location Longitude": "קו האורך של הפרויקט",
    "Project Logo": "לוגו הפרויקט",
    file: "קובץ",
    "Zoom in to activate this feature":
      "על מנת להפעיל אפשרות זו יש להגדיל את התצוגה",
    "Cabinet Devices": "בקרים מקושרים למרכזיה",
    "Unassigned Devices": "בקרים לא מקושרים",
    "Other Cabinet Devices": "בקרים מקושרים למרכזיה אחרת",
    "Are You Sure you want to delete this file":
      "האם אתה בטוח שברצונך למחוק את הקובץ",
    "The file name can not include spaces, hebrew letters and some signs":
      "שם הקובץ לא יכול להכיל רווחים, אותיות בעברית וסימנים מסויימים",
    PRINT: "הדפס",
    Print: "הדפס",
    "2 ways for selecting days": "2 שיטות לבחירת ימים",
    "Select a specific day by clicking on day in the calendar": `בחר יום מסוים ע"י לחיצה על יום ביומן`,
    "For example": "לדוגמא",
    "January 6": "6 בינואר",
    "Select a specific day for the current three months":
      "בחר יום מסוים עבור 3 החודשים הנוכחיים",
    "by clicking on the specific day (or days) at the top bar": `ע"י לחיצה בבר העליון על יום( או ימים)`,
    "All Wednesdays and Fridays": "כל ימי רביעי ושישי",
    "Select Curves": "בחר גרף עמעום",
    "No More Curves To Select": "לא קיימים גרפים נוספים לבחירה",
    "First you must select a curve": "תחילה עליך לבחור גרף עמעום",
    "Schedules Updated Successfully": "התזמונים עודכנו בהצלחה",
    "Are you sure you want to save changes?":
      "האם אתה בטוח שברצונך לשמור שינויים?",
    "Save changes before exiting?": "לשמור שינויים לפני היציאה?",
    "Manage Selected Devices": "נהל מכשירים נבחרים",
    Pole: "עמוד",
    "Select an Action": "בחר פעולה",
    "Insert Data": "הכנס מידע",
    Command: "פעולה",
    "Create Group": "יצר קבוצה",
    "Update Status": "עדכן סטטוס",
    "Updade Device Status": "עדכן סטטוס של מכשיר",
    "Items per page": "מכשירים בעמוד",
    "Choose Devices": "בחר מכשירים",
    "Exit device manager": "צא מניהול מכשירים",
    "Select Exported Fields": "בחר שדות ליצוא",
    "Device Latitude": "קו הרוחב",
    "Device Longitude": "קו האורך",
    "Device Commission Date": "תאריך התקנה",
    "Device Commissioner": "מתקין המכשיר",
    "Add Inserted Field Values": "הכנס ערכים לשדות",
    "Selected Command": "בחר פעולה",
    "Add Group Details": "הכנס פרטי קבוצה",
    "Apply Filter": "החל סנן",
    "Remove Devices": "הורד מכשירים",
    "Export Installation Fail": "יצא התקנות שנכשלו",
    "Clear Filter": "נקה סינון",
    "File Description": "תיאור קובץ",
    "Expiration Date": "תאריך התפוגה",
    "Scheduled Notice": "תזמון התרעה",
    "Expiration date": "תאריך התפוגה",
    "A week before the expiration date": "שבוע לפני תאריך התפוגה",
    "One month before the expiration date": "חודש לפני תאריך התפוגה",
    "Three months before the expiration date": "שלושה חודשים לפני תאריך התפוגה",
    "Engineer inspection approval": "אישור בדיקת מהנדס",
    "Electrical diagram": "שירטוט חשמלי",
    "Cabinet picture": "צילום מרכזיה",
    "Cabinet installation site picture": "צילום איזור התקנת המרכזיה",
    Other: "אחר",
    "Successfully added file": "קובץ הוכנס בהצלחה",
    "Successfully deleted file": "קובץ נמחק בהצלחה",
    "Successfully edited file": "קובץ נערך בהצלחה",
    "Tondo Notification": "התרעות טונדו",
    "Close Notification": "סגור התרעה",
    "Controlled Fixtures": "גופי תאורה מבוקרים",
    "Connected And Active": "מחוברים ופעילים",
    "Name Is Required": "נא להזין שם משתמש",
    "Password Is Required": "נא להזין סיסמא",
    "Water Meters": "מוני מים",
    "Water Meter": "מונה מים",
    "WATER METER": "מונה מים",
    "Battery Level": "רמת סוללה",
    "Last Transmission": "שידור אחרון",
    "others": "אחרים",
    "Schedule was saved successfully": "התזמון נשמר בהצלחה",
    "Astronomical": "שעון אסטרונומי",
    "Choose Clock Type": "בחר סוג שעון",
    "Configure Schedule For Assigned Devices": "הגדר תזמון עבור בקרים משויכים",
    "Start Offset": "הזזה בהתחלה",
    "End Offset": "הזזה בסוף",
    "Start Time": "זמן התחלה",
    "End Time": "זמן סיום",
    "Schedule Active": "תזמון פעיל",
    "Set Time": "הגדר זמן",
    "Before(-)/After(+)": "לפני(-)/אחרי(+)",
    "Schedule cannot be set on the assigned devices": "לא ניתן להגדיר תזמון על הבקרים המשויכים",
    "Start and end time must be different": "זמן התחלה וסיום לא יכולים להיות זהים",
    "There are no alerts for this cabinet": "לא קיימות התרעות למרכזיה זו",
    "There are no incidents for this cabinet": "לא קיימות התרעות למרכזיה זו",
    "There are no events for this cabinet": "לא קיימים אירועים למרכזיה זו",
    "Loading devices, please wait": "טוען מכשירים, אנא המתן",
    "Basic Map": "מפה בסיסית",
    "Advanced Map": "מפה מתקדמת",
    "General Settings": "הגדרות כלליות",
    "General": "כללי",
    "User Settings": "הגדרות משתמש",
    "Project Settings": "הגדרות פרויקט",
    "Default Map": "מפת ברירת המחדל",
    "Select Default Map": "בחר מפת ברירת מחדל",
    "Basic": "בסיסי",
    "Default map successfully saved": "מפת ברירת המחדל נשמרה בהצלחה",
    "Version Number": "מספר גרסה",
    "Welcome to Tondo Dashboard": "ברוכים הבאים ל-Tondo Dashboard",
    "For convenience, select one of the following": "באפשרותך לבחור בין שני סוגים של מפות",
    "A basic Controller display with just the essential statuses": "תצוגה עם מצבי בקרים חיוניים בלבד",
    "Offers a more detailed Controller statuses display": "מציעה תצוגה מפורטת יותר של מצבי הבקרים",
    "You can always switch later": "באפשרותך לשנות את בחירתך מאוחר יותר",
    "Water Level Sensor Triggered" : "חיישן מפלס מים הופעל",
    "Water Level": "מפלס מים",
    "Water Level Chart": "גרף מפלס המים",
    "TOP SENSOR": "חיישן עליון",
    "BOTTOM SENSOR": "חיישן תחתון",
    "Measering the height of the water": "מודד את גובה המים",
    "Indicating if the sensor wet or not": "מציין האם החיישן רטוב/לא",
    "Wet": "רטוב",
    "Dry": "יבש",
    "threshold": "סף",
    "sensor data": "נתוני החיישן",
    "FLOOD SENSOR": "חיישן הצפה",
    "Flood Sensor": "חיישן הצפה",
    "flood sensor": "חיישן הצפה",
    "Sensor Alert": "התרעת חיישן",
    "Top Float Alert": "התרעת מצוף עליון",
    "Bottom Float Alert": "התרעת מצוף תחתון",
    "Barrier Open": "מחסום פתוח",
    "Barrier Closed": "מחסום סגור",
    "BARRIER": "מחסום",
    "MOTION SENSOR": "חיישן תנועה",
    "Motion Sensor": "חיישן תנועה",
    "motion sensor": "חיישן תנועה",
    "Triggered": "הופעל",
    "Not Triggered": "לא הופעל",
    "Bottom Sensor Height": "גובה חיישן תחתון",
    "Top Sensor Height": "גובה חיישן עליון",
    "Bottom Sensor Status": "סטטוס חיישן תחתון",
    "Top Sensor Status": "סטטוס חיישן עליון",
    "cm": `ס"מ`,
    "Ticket ID": "מזהה כרטיס",
    "Cabinet Alerts": "התרעות מרכזיה",
    "Flood Alerts": "התרעות הצפה",
    "Water Level Monitor": "ניטור מפלס המים",
    "Water level sensor": "חיישן מפלס המים",
    "This sensor measures the water level": "החיישן מודד את מפלס המים",
    "An alert is sent when it crosses the threshold": "התרעה נשלחת ברגע שמפלס המים חוצה את הסף",
    "When a float detects water, an alert is sent to the system": "כאשר המצוף מזהה מים, התרעה נשלחת למערכת",
    "Nahal Tsihor": "נחל ציחור",
    "Critical flood alert on road": "התרעת שיטפון קריטית בכביש",
    "Critical flood alert in creek": "התרעת שיטפון קריטית בנחל",
    "Sensor triggered at": "חיישן הופעל ב",
    "Float at": "מצוף ב",
    "Bottom Float": "מצוף תחתון",
    "Top Float": "מצוף עליון",
    "at": "ב",
    "Barrier is": "המחסום",
    "barrier": "מחסום",
    "The device can be controlled as long as it is connected and active": "ניתן לשלוט במכשיר כל עוד הוא מחובר ופעיל",
    "Email Address": "כתובת אימייל",
    "New Password": "סיסמא חדשה",
    "Confirm New Password": "אשר סיסמא חדשה",
    "Sorry, but page not found": "סליחה, אבל הדף לא נמצא",
    "Back To Login": "בחזרה להתחברות",
    "Cabinet Id": "מספר מרכזיה",
    "You must choose at least one field to update": "עליך לבחור לפחות שדה אחד לעדכון מתוך הרשימה",
    "Scan Numbering": "מספר סריקה",
    "Total Scan Number": "מספר סריקה כולל",
    "Fixture P/n": `מק"ט יצרן גוף התאורה`,
    "Haimast-date Of Elevator Inspection": "תאריך בדיקת מעלית היימסט",
    "Iec Contract Number": `מספר חוזה חח"י`,
    "Iec's Customer Id": "מספר לקוח בחברת חשמל",
    "Energy Meter Number": "מספר מונה",
    "Iec's Supply Pole Id": "מספר עמוד מזין של מרכזיה",
    "Cabinet Iec's Meter Id": "מספר מונה של מרכזיה",
    "Opps, something went wrong with the data": "אופס, משהו בנתונים השתבש",
    "experimental": "ניסיוני",
    "Are you sure you want to change device status": "האם אתה בטוח שאתה רוצה לשנות את סטטוס המכשירים",
    "There are no filters to display": "לא קיימים מסננים להצגה",
    "Event Type": "סוג אירוע",
    "Alert Type": "סוג התרעה",
    "Power supply failure": "כשל באספקת החשמל",
    "Barometric Pressure": "לחץ ברומטרי",
    "Air Temperature": "טמפרטורת אויר",
    "Dew Point Temperature": "טמפרטורת נקודת הטל",
    "Air Humidity": "לחות האויר",
    "Wind Direction": "כיוון הרוח",
    "Wind Speed": "מהירות הרוח",
    "WEATHER STATION": "תחנת מזג אויר",
    "Weather Station": "תחנת מזג אויר",
    "weather station": "תחנת מזג אויר",
    "You must insert on time": "עליך להזין זמן הדלקה",
    "You must insert off time": "עליך להזין זמן כיבוי",
    "On and Off time must be different": "עליך להזין זמן הדלקה וכיבוי שונים",
    "Connection settings were successfully updated": "הגדרות החיבור עודכנו בהצלחה",
    "RS485 Error": "תקלת RS485",
    "RS485_error": "תקלת RS485",
    "Curve name already exists": "כבר קיים גרף עם שם זהה",
    "Please choose a unique name": "בחר שם ייחודי",
    "Incorrect username or password": "שם משתמש או סיסמא לא נכונים",
    "User is inactive, please contact support": "המשתמש אינו פעיל, צור קשר עם התמיכה",
    "Incorrect email address": "כתובת אימייל לא נכונה",
    "something went wrong": "משהו השתבש",
    "Choose File": "בחר קובץ",
    "No File Chosen": "לא נבחר קובץ",
    "VERSION LIST": "מספרי גרסה",
    "Date Treated": "תאריך טיפול",
    "Last Date Check": "תאריך בדיקה אחרונה",
    "Video Guides": "מדריכי וידאו",
    "Multiple selection function": "פונקציית בחירה מרובה",
    "Creating a cabinet schedule": "יצירת תזמון מרכזיה",
    "Creating schedule using groups and creating new dimming curve": "יצירת תזמון באמצעות קבוצות ויצירת עקומת עמעום חדשה",
    "Creating a new group": "יצירת קבוצה חדשה",
    "Creating a new filter": "יצירת פילטר חדש",
    "Change device location": "שינוי מיקום מכשיר",
    "Alert notifications": "קבלת התרעות",
    "Invalid email address": "כתובת אימייל לא תקינה",
    "SEND": "שלח",
    "Installation Date": "תאריך התקנה",
    "There are no matching results for your filter": "לא קיימות תוצאות מתאימות עבור הסינון שלך",
    "Malfunctioning Units": "יחידות תקולות",
    "No Data": "אין מידע",
    "NO DATA": "אין מידע",
    "Password has expired, please reset it by clicking on 'Frogot Password?'": "תוקף הסיסמא פג, לחץ על 'שכחת סיסמא?' כדי לאפס אותה",
    "Cabinets With Alerts": "מרכזיות עם התרעות",
    "LIVE data": "נתונים בזמן אמת",
    "RECORDED data": "נתונים היסטוריים",
    "Energy Data Unavailable": "אין נתוני אנרגיה זמינים",
    "Critical Alerts Service": "שירות התרעות קריטיות",
    "Alerts active during the night": "התרעות פועלות רק במהלך הלילה",
    "24/7 Alert Service": "התרעות 24/7",
    "Alerts service is inactive": "שירות התרעות לא פעיל",
    "Devices astronomical updater": `תזמון אסטרונומי למכשירים הוגדר ע"י`,
    "Devices schedule updater": `תזמון קבוע למכשירים הוגדר ע"י`,
    "Operation State": "מצב תפעולי",
    "Installed by": `הותקן ע"י`,
    "No installer details": "אין מידע לגבי המתקין",
    "Installing": "בהסבה",
    "Operational": "באחזקה",
    "Operational since": "באחזקה מאז",
    "Retrofit": "בפיתוח",
    "Retrofit": "בפיתוח",
    "Retrofit since": "בפיתוח מאז",
    "Operation state updated successfully": "המצב התפעולי עודכן בהצלחה",
    "Add Input": "הוסף כניסה",
    "Add Output": "הוסף יציאה",
    "Today Schedule": "תזמון להיום",
    "Triggers": "טריגרים",
    "Start time": "התחלה",
    "End time": "סיום",
    "Output Number": "מספר יציאה",
    "Input Number": "מספר כניסה",
    "Days": "ימים",
    "Monitored Inputs": "כניסות מנוטרות",
    "Edit Input": "ערוך כניסה",
    "Edit Output": "ערוך יציאה",
    "Required field": "שדה חובה",
    "No schedule defined": "לא הוגדר תזמון",
    "No triggers defined": "לא הוגדרו טריגרים",
    "Next": "הבא",
    "Exit": "יציאה",
    "Main Schedule": "תזמון ראשי",
    "Special Days": "ימים מיוחדים",
    "Click to": "לחץ כדי",
    "add schedule": "להוסיף תזמון",
    "add trigger": "להוסיף טריגר",
    "No special days defined": "לא הוגדרו ימים מיוחדים",
    "Add main schedule first": "עליך להוסיף קודם תזמון ראשי",
    "Finish": "סיום",
    "add special day schedule": "להוסיף תזמון ליום מיוחד",
    "Before": "לפני",
    "After": "אחרי",
    "Input State": "מצב הכניסה",
    "Trigger": "טריגר",
    "Add trigger": "הוסף טריגר",
    "These parameters will overwrite the existing schedule": "תזמונים אלה יחליפו את התזמון הראשי",
    "You must choose at least one day": "עליך לבחור יום אחד לפחות",
    "On Label": "תגית הדלקה",
    "Off Label": "תגית כיבוי",
    "Discard": "השלך",
    "Are you sure you want to delete this connection?": "האם אתה בטוח שאתה רוצה למחוק את החיבור?",
    "Connection was saved successfully": "החיבור נשמר בהצלחה",
    "Connection was deleted successfully": "החיבור נמחק בהצלחה",
    "Delivery State": "מצב מסירה",
    "No output defined Click to": "לא הוגדרה יציאה לחץ כדי ",
    "No input defined Click to": "לא הוגדרה כניסה לחץ כדי",
    "add output": "להוסיף",
    "add input": "להוסיף",
    "Your permissions have changed, please loggin again": "ההרשאות שלך השתנו, התחבר מחדש",
    "Failed to add file": "העלאת הקובץ לא הצליחה",
    "Failed to edit file": "עריכת הקובץ לא הצליחה",
    "Failed to delete file": "מחיקת הקובץ לא הצליחה",
    "Oops something went wrong, please login again": "אופס משהו השתבש, התחבר מחדש",
    "Found no results for": "לא נמצאו תוצאות עבור",
    "You're not authorized to perform this action": "אינך מורשה לבצע פעולה זו",
    "Group name can contain only the characters": "שם הקבוצה יכול להכיל רק את התווים",
    "without spaces": "ללא רווחים",
    "password must contain at least 8 characters": "הסיסמא חייבת להכיל 8 תווים לפחות",
    "Password should contain at least 1 number": "הסיסמא חייבת להכיל לפחות מספר אחד",
    "Password should contain at least 1 lowercase": "הסיסמא חייבת להכיל לפחות אות קטנה אחת",
    "Password should contain at least 1 uppercase": "הסיסמא חייבת להכיל לפחות אות גדולה אחת",
    "Password should contain at least one of the signs": "הסיסמא חייבת להכיל לפחות את אחד מהסימנים",
    "Password should contain only the characters": "הסיסמא חייבת להכיל רק את התווים",
    "First name must contains only the characters": "שם פרטי חייב להכיל רק את התווים",
    "Last name must contains only the characters": "שם משפחה חייב להכיל רק את התווים",
    "Email can't contain hebrew letters": "אימייל לא יכול להכיל תווים בעברית",
    "User deleted successfully": "המשתמש נמחק בהצלחה",
    "Couldn't delete user": "מחיקת המשתמש נכשלה",
    "Couldn't create this user": "יצירת המשתמש נכשלה",
    "Couldn't create virtual device": "יצירת המכשיר נכשלה",
    "User added successfully": "המשתמש נוצר בהצלחה",
    "User data updated successfully": "הנתונים נשמרו בהצלחה",
    "Some of the user's information is not saved": "חלק מהנתונים לא נשמרו",
    "Notified projects": "פרויקטים לקבלת התרעות",
    "Hebrew is not valid in first name": "שם פרטי לא יכול להכיל אותיות בעברית",
    "Hebrew is not valid in last name": "שם משפחה לא יכול להכיל אותיות בעברית",
    "Alert severity": "רמת התרעה",
    "high": "גבוהה בלבד",
    "medium": "בינונית ומעלה",
    "low": "נמוכה ומעלה",
    "Cabinet Alerts": "התרעות מרכזיה",
    "Single Unit Alerts": "התרעות יחידה בודדת",
    "Device Id": "מספר מכשיר",
    "no data": "אין מידע",
    "This file already exists": "הקובץ כבר קיים",
    "Device not found": "המכשיר לא נמצא",
    "Back To Home Page": "חזור לדף הבית",
    "Close Incident": "סגור התרעה",
    "Close Event": "סגור אירוע",
    "Device Address": "כתובת המכשיר",
    "Are you sure you want to close this incident?": "האם אתה בטוח שאתה רוצה לסגור את ההתרעה?",
    "The incident was closed successfully": "ההתרעה נסגרה בהצלחה",
    "Couldn't close the incident": "סגירת ההתרעה נכשלה",
    "Closing the incident": "סוגר את ההתרעה",
    "Notes": "הערות",
    "Cabinet Description": "תיאור מרכזיה",
    "Incidents": "התרעות",
    "Connected Percent": "אחוז מחוברים",
    "Alerts service not exist": "שירות התרעות לא קיים",
    "Alerts service is off": "שירות התרעות כבוי",
    "Cabinet IQ alerts service is on": "שירות התרעות מרכזיה פעיל",
    "Cabinet IQ alerts service off": "שירות התרעות מרכזיה כבוי",
    "Cabinet IQ alerts service not exist": "שירות התרעות מרכזיה לא קיים",
    "Power service is on": "שירות תאורה פעיל",
    "Power service is off": "שירות תאורה כבוי",
    "Power service not exist": "שירות תאורה לא קיים",
    "Devices schedule is off": "תזמון למכשירים כבוי",
    "Devices schedule not exist": "לא קיים תזמון למכשירים",
    "Details": "פרטים",
    "Fixed": "קבוע",
    "TOU": `תעו"ז`,
    "Tariff Type": "סוג תעריף",
    "Tariff": "תעריף",
    "Tariff 1": "תעריף 1",
    "Tariff 2": "תעריף 2",
    "Tariff 3": "תעריף 3",
    "Cabinet tariff updated successfully": "תעריף המרכזיה עודכן בהצלחה",
    "a few seconds ago": "לפני מספר שניות",
    "a minute ago": "לפני דקה",
    "{{number}} minutes ago": "לפני {{number}} דקות",
    "an hour ago": "לפני שעה",
    "{{number}} hours ago": "לפני {{number}} שעות",
    "a day ago": "לפני יום",
    "{{number}} days ago": "לפני {{number}} ימים",
    "a week ago": "לפני שבוע",
    "{{number}} weeks ago": "לפני {{number}} שבועות",
    "a month ago": "לפני חודש",
    "{{number}} months ago": "לפני {{number}} חודשים",
    "a year ago": "לפני שנה",
    "{{number}} years ago": "לפני {{number}} שנים",
    "Reset Selection": "אפס בחירה",
    "Table": "טבלה",
    "Incident Type": "סוג התרעה",
    "Date & Time": "תאריך & שעה",
    "Relative Time": "זמן יחסי",
    "Data collected at": "נתונים שנאספו ב",
    "Retrieve last data": "אחזר נתונים אחרונים",
    "Pick date & time": "בחר תאריך ושעה",
    "Ok": "בחר",
    "Profile": "פרופיל",
    "Permissions": "הרשאות",
    "First Name": "שם פרטי",
    "Last Name": "שם משפחה",
    "Email with password reset successfully sent": "אימייל עם איפוס סיסמא נשלח בהצלחה",
    "Couldn't send email with password reset": "שליחת האימייל נכשלה",
    "Set User Password": "הגדר סיסמא",
    "Send reset email": "שלח אימייל עם איפוס סיסמא",
    "Or": "או",
    "Preferences": "העדפות",
    "MFA is on": "אימות דו שלבי פעיל",
    "MFA is off": "אימות דו שלבי לא פעיל",
    "No dashboard access": "ללא גישה לדשבורד",
    "Dashboard access": "גישה לדשבורד",
    "Dashboard Access": "גישה לדשבורד",
    "Notifications are on": "התרעות פעילות",
    "Notifications are off": "התרעות לא פעילות",
    "Notifications Severity": "חומרת ההתרעות",
    "User Role": "הרשאת משתמש",
    "Mobile Roles": "הרשאות אפליקציה",
    "Notifications": "התרעות",
    "Bluetooth": "בלוטוס",
    "admin": "מנהל מערכת",
    "Admin": "מנהל מערכת",
    "Readonly User": "משתמש לקריאה בלבד",
    "No roles assigned yet": "לא הוקצו הרשאות",
    "No projects assigned yet": "לא הוקצו פרויקטיים",
    "all": "הכל",
    "Manage Password": "נהל סיסמא",
    "Edit Profile": "ערוך פרופיל",
    "User Profile": "פרופיל משתמש",
    "New User": "משתמש חדש",
  }
};

export default locales; 