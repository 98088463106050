import { Route } from '@/modules/Types';

const routes: Route[] = [
  {
    path: '/sign',
    name: 'Login',
    meta: {
      auth: false,
      header: false,
      footer: false,
      sidebar: false,
      container: {
        fit: true,
        flex: true
      }
    }
  },
  {
    path: '/companies',
    name: 'Companies',
    meta: {
      auth: true,
      footer: false,
      sidebar: false,
      container: {
        fit: true,
        flex: true
      }
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    meta: {
      auth: true,
      footer: false,
      sidebar: false,
      container: {
        fit: true,
        flex: true
      }
    }
  },
  {
    menuTitle: 'Projects Overview',
    menuIcon: 'mdi-chart-line',
    path: '/fullViewReport',
    name: 'FullViewReport',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/fullViewReport/:tab',
    name: 'FullViewReportCabinetAlerts',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    menuTitle: 'Full View',
    menuIcon: 'mdi-map-marker',
    path: '/',
    name: 'MapView',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/allProjectsAlerts',
    name: 'AlertManagementAllProjects',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    name: 'FilterView',
    menuTitle: 'Filter Views',
    menuIcon: 'mdi-filter',
    meta: {
      auth: true,
      company: true
    },
    children: [
      {
        path: '/filter/create',
        menuTitle: 'Create New Filter',
        menuIcon: 'mdi-plus',
        name: 'CreateFilter',
        meta: {
          auth: true,
          company: true
        }
      }
    ]
  },
  {
    menuTitle: 'Group Management',
    menuIcon: 'mdi-group',
    path: '/groups',
    name: 'GroupsList',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/groups/create',
    name: 'CreateGroup',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/groups/edit/:id',
    name: 'EditGroup',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/groups/:id',
    name: 'GroupItem',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    menuIcon: 'mdi-calendar-clock',
    path: '/schedules',
    name: 'ScheduleManagement',
    menuTitle: 'Schedule Management',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/schedules/:id',
    name: 'EditSchedule',
    menuTitle: 'Schedule',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/schedules/create',
    name: 'CreateSchedule',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/curve/:id',
    name: 'EditCurve',
    menuTitle: 'Curve Management',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/curve/create',
    name: 'CreateCurve',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/reports/list',
    name: 'Reports',
    menuTitle: 'Reports',
    menuIcon: 'description',
    meta: {
      auth: true,
      company: true
    }
  },
  // {
  //   path: '/reports/installations',
  //   name: 'InstallationsReport',
  //   meta: {
  //     auth: true,
  //     company: true
  //   }
  // },
  {
    path: '/:location/:pid/cabinet-details/:id',
    name: 'CabinetIncidents',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/alerts',
    name: 'AlertManagment',
    menuTitle: 'Alert Management',
    menuIcon: 'mdi-alert',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/alerts/:pid/:filter',
    name: 'AlertManagmentByFilter',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/:location/alerts/:pid',
    name: 'FullViewReportAlertAllProjects',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/events',
    name: 'EventManagment',
    menuTitle: 'Event Management',
    menuIcon: 'mdi-calendar-edit',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/reports/inventory_management',
    name: 'InventoryReport',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/reports/activity_log',
    name: 'ActivityLogReport',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/reports/power_consumption_by_device',
    name: 'PowerConsumptionReport',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/reports/commissioned_scanned',
    name: 'CommissionedManagerReport',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/reports/commissioned_unscanned',
    name: 'CommissionedUnscannedReport',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/cabinet-details/:id',
    name: 'CabinetDetails',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/reports/power_consumption_by_satec',
    name: 'SatecPowerConsumptionReport',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/reports/monitoring_report',
    name: 'MonitoringReport',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    path: '/cabinet-management',
    name: 'CabinetManagement',
    menuImg: 'sidebar_cabinet.svg',
    menuSelectedImg: 'sidebar_selected_cabinet.svg',
    menuTitle: 'Cabinets',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    menuTitle: 'Sensors',
    name: 'Sensors',
    menuIcon: 'mdi-access-point-network',
    meta: {
      auth: true,
      company: true
    },
    children: [
      {
        path: '/sensors/water-meter',
        name: 'WaterMeter',
        menuTitle: 'Water Meter',
        menuImg: 'sidebar_water_meter.svg',
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/sensors/motion-sensor',
        name: 'MotionSensor',
        menuTitle: 'Motion Sensor',
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/sensors/flood-sensor',
        name: 'FloodSensor',
        menuTitle: 'Flood Sensor',
        menuImg: 'sidebar_flood_sensor.svg',
        meta: {
          auth: true,
          company: true
        }
      }
    ]
  },
  {
    path: '/general-settings',
    name: 'GeneralConfigAllProjects',
    menuTitle: 'General Settings',
    menuIcon: 'settings',
    meta: {
      auth: true,
      company: true
    }
  },
  {
    name: 'Settings',
    menuTitle: 'Settings',
    menuIcon: 'settings',
    meta: {
      auth: true,
      company: true
    },
    children: [
      {
        path: '/settings/project-contacts',
        name: 'ProjectContacts',
        menuTitle: 'Project Contacts',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      },
      // {
      //   path: '/settings/virtual-devices',
      //   name: 'AddVirtual',
      //   menuTitle: 'Virtual Devices',
      //   menuIcon: 'description',
      //   meta: {
      //     auth: true,
      //     company: true
      //   }
      // },
      {
        path: '/settings/cabinets',
        name: 'ConfigCabinets',
        menuTitle: 'Cabinets',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/settings/general',
        name: 'GeneralConfigurations',
        menuTitle: 'General',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      },
      // {
      //   path: '/settings/periodic',
      //   name: 'PeriodicReports',
      //   menuTitle: 'Periodic Reports',
      //   menuIcon: 'description',
      //   meta: {
      //     auth: true,
      //     company: true
      //   }
      // },
      {
        path: '/settings/device-status',
        name: 'ChangeDeviceStatus',
        menuTitle: 'Device Status',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/settings/group-status',
        name: 'ChangeGroupStatus',
        menuTitle: 'Group Status',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/settings/users-management',
        name: 'UsersManagement',
        menuTitle: 'User Management',
        menuIcon: 'description',
        role: ['CA', 'CM'],
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/settings/bulk-uploader',
        name: 'BulkUploader',
        menuTitle: 'Bulk Uploader',
        menuIcon: 'description',
        role: ['CA'],
        meta: {
          auth: true,
          company: true
        }
      }
    ]
  },
  {
    name: 'Help',
    menuTitle: 'Help',
    menuIcon: 'mdi-help-box',
    meta: {
      auth: true,
      company: true
    },
    children: [
      {
        path: '/user-guide',
        name: 'UserGuide',
        menuTitle: 'User Guide',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/video-guides',
        name: 'VideoGuides',
        menuTitle: 'Video Guides',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/about',
        name: 'About',
        menuTitle: 'About',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      }
    ]
  },
  {
    path: '/authentication/reset_password',
    name: 'ResetPassword'
  },
  {
    path: '/manager-options',
    name: 'ManagerOptions',
    menuTitle: 'Manager Options',
    menuIcon: 'mdi-account-key',
    meta: {
      auth: true,
      company: true
    },
    children: [
      {
        path: '/manager-options/reports',
        name: 'ManagerReports',
        menuTitle: 'Reports',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/manager-options/network-management',
        name: 'NetworkManagement',
        menuTitle: 'Network Management',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      },
      {
        path: '/manager-options/excel-upload',
        name: 'ExcelUpload',
        menuTitle: 'Excel Upload',
        menuIcon: 'description',
        meta: {
          auth: true,
          company: true
        }
      }
    ]
  },
  {
    path: '/page-not-found',
    name: 'Error404'
  },
  {
    path: '*',
    redirect: '/page-not-found'
  }
];
export default routes;