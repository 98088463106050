import store from 'store';
import mapConfig from '@/pages/mapView/components/map/map.config';
import vuex from '@/store';
import Utils from '@/modules/Utils';

export enum Types {
  DEVICE_CLASSES = 'deviceclasses',
  PROPS = 'properties',
  DEVICES = 'devices',
  COMPANIES = 'companies',
  PROJECTS = 'projects'
}

export enum Methods {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  HEAD = 'head',
  PATCH = 'patch',
  DELETE = 'delete'
}

export enum DataMethods {
  QUERY = 'query',
  OBJECT = 'object',
  FORMDATA = 'formData'
}

export interface Parameters {
  auth: string;
  apiURL?: string;
  apiKey?: string;
  serialize: boolean;
  dataMethod: string;
  contentType: string;
  itemID?: string;
  query: any;
  report: boolean;
}

export default class API {
  public static readonly API_URL = process.env.VUE_APP_HTTP_SERVER;
  public static readonly EXTERNAL_API_KEY = '6F4hqcuoUC7OQARKpDvGh2Sh9ghs0YzF7EcwTPtM';
  public static readonly DASHBOARD_API_URL = process.env.VUE_APP_DASHBOARD_API;
  public static readonly BACKEND_SERVICES_URL = process.env.VUE_APP_BACKEND_SERVICES_API;

  public static get(type, path?, data?, params?, version?) {
    return this.knock(type, path, data, Methods.GET, params, version);
  }

  public static put(type, path?, data?, params?, version?) {
    return this.knock(type, path, data, Methods.PUT, params, version);
  }

  public static post(type, path?, data?, params?, version?) {
    return this.knock(type, path, data, Methods.POST, params, version);
  }

  public static head(type, path?, data?, params?, version?) {
    return this.knock(type, path, data, Methods.HEAD, params, version);
  }

  public static patch(type, path?, data?, params?, version?) {
    return this.knock(type, path, data, Methods.PATCH, params, version);
  }

  public static delete(type, path?, data?, params?, version?) {
    return this.knock(type, path, data, Methods.DELETE, params, version);
  }

  public static async dashboardAPI(url, method, headers, body) {
    return API.externalAPI(`${API.DASHBOARD_API_URL}${url}`, method, headers, body);
  }

  public static async backendServicesAPI(url, method, headers, body) {
    return API.externalAPI(`${API.BACKEND_SERVICES_URL}${url}`, method, headers, body);
  }

  public static async externalAPI(url, method, headers, body) {
    if ((vuex.state as any).Global.show_not_allowed_user_dialog) {
      return;
    }

    const user_api_key = await JSON.parse(localStorage.getItem('user')).apikey;
    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': user_api_key,
        ...headers
      }
    };

    if (method !== 'GET') {
      options['body'] = JSON.stringify(body);
    }

    try {
      const response = await fetch(url, options);
      if (response && response.status === 403) {
        Utils.handleNotAllowedUser();
      }
      if (!response.ok) throw response;
      else {
        const text = await response.text();
        try {
          return JSON.parse(text);
        } catch (e) {
          return text;
        }
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public static async googleAPI(url, method, body) {
    url = `${url}?key=${mapConfig.TOKEN_GOOGLE}`;

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) throw response;
      else {
        const text = await response.text();
        try {
          return JSON.parse(text);
        } catch (e) {
          return text;
        }
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  private static async knock(
    type: Types,
    path: string = '',
    body: any = {},
    method: Methods = Methods.POST,
    params?: Parameters,
    version?: string
  ): Promise<any> {

    if ((vuex.state as any).Global.show_not_allowed_user_dialog) {
      return;
    }

    const headers = {
      'Cache-Control': 'no-cache',
      ['Accept']: 'application/json, text/plain, */*'
    };

    params = Object.assign(
      {
        query: {},
        auth: 'user',
        serialize: false,
        apiURL: this.API_URL,
        dataMethod: DataMethods.OBJECT,
        contentType: 'application/json'
      } as Parameters,
      params
    );
    
    if (!params.apiKey) {
      params.apiKey = (store.get(params.auth) || {}).apikey;
    }

    if (params.contentType) {
      headers['Content-Type'] = params.contentType;
    }

    if (params.auth && params.apiKey) {
      headers['X-API-KEY'] = params.apiKey;
    }

    if (params.serialize || [Methods.GET].indexOf(method) !== -1) {
      params.dataMethod = DataMethods.QUERY;
    }

    switch (params.dataMethod) {
      case DataMethods.OBJECT:
        body = body ? JSON.stringify(body) : null;
        break;

      case DataMethods.QUERY:
        body = this.serialize(body) as any;
        break;

      case DataMethods.FORMDATA:
        const formData = new FormData();
        for (const k in body) formData.append(k, body[k]);
        body = formData;
        break;
    }

    let url;
    if ([Methods.GET, Methods.DELETE, Methods.HEAD].indexOf(method) !== -1) {
      url = this.getAPI(type, path, params, body);
      body = null;
    } else {
      url = this.getAPI(type, path, params, this.serialize(params.query));
    }

    const v = version ? version : 'v3';

    try {
      const response = await fetch(params.apiURL + v + url, {
        method: method.toUpperCase(),
        body,
        headers
      });
      
      if (response && response.status === 403 && params.auth && params.apiKey) {
        Utils.handleNotAllowedUser();
      }

      if (!response.ok) throw response;
      else {
        const text = await response.text();
        try {
          if (text.length > 0) {
            return JSON.parse(text);
          } else return response;
        } catch (e) {
          return text;
        }
      }
    } catch (e) {
      if (url.includes('reporting_query')){
        const bad_status = [400, 408, 502, 503, 504];
        if (bad_status.includes(e['status'])){
          try {
            const response = await fetch(params.apiURL + v + url, {
              method: method.toUpperCase(),
              body,
              headers
            });
            if (!response.ok) throw response;
            else {
              const text = await response.text();
              try {
                if (text.length > 0) {
                  return JSON.parse(text);
                } else return;
              } catch (e) {
                return text;
              }
            }
          }catch (e){
            console.log(e);
            throw e;
          }
        }else throw e;
      }else {
        console.log(e);
        throw e;
      }
    }
  }

  private static serialize(obj): string {
    if (!obj) return '';

    const str: string[] = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p))
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    return str.join('&');
  }

  private static getAPI(type: Types, request, params, query?: string) {
    let itemID = '';
    switch (type) {
      case Types.COMPANIES:
        itemID = !params.hasOwnProperty('itemID') ? '' : params.itemID;
        break;

      case Types.PROJECTS:
      case Types.DEVICES:
        itemID = params.itemID;
        break;
    }

    let url = `/${type}/${itemID || ''}/${request}/${query ? '?' + query : ''}`;
    while (/\/\//.test(url)) {
      url = url.replace('//', '/');
    }

    return url;
  }
}
