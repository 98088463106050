import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

import vuex from '..';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import API, { Types } from '../../modules/API';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import router from '../../router';
import store from 'store';

export interface Project {
  id: string;
  name: string;
  company: string;
  description: string;
  secret: string;
  logo: any;
  created: Date;
  modified: Date;
  users: number[];
  group_id: number;
  restricted: string[];
  notificationStreamId: string;
}

export interface ProjectMetadata {
  id: string;
  power: object[];
  screw: object[];
  painted: object[];
  activity: object[];
  pole: object[];
  totalDevices: number;
  commissionedByDate: object[];
}

// let devicesDB, cabinetsDB;
@Module({ namespaced: true })
export default class Projects extends VuexModule {
  list: Project[] = [];
  projects_count: number = 0;
  alerts: object = {};
  events: object = {};
  images: object = {};
  projects_metadata: any = new Map();
  projects_metadata_size: number = 0;
  // projects_users: any = new Map();

  @Action
  async load() {
    vuex.commit('Projects/flush');
    vuex.commit('User/setIsLoading', true);

    const user = store.get('user');
    try {
      API.get(Types.COMPANIES, `${user.company}/${Types.PROJECTS}`, {}).then((res) => {
        if (res && res.results) {
          vuex.commit('Projects/setProjectsCount', res.results.length);
          
          Promise.all(
            res.results.map((data) => {
              vuex.dispatch('Projects/add', data);
              vuex.dispatch('Projects/loadImage', {
                project_id: data.id,
                company_id: data.company
              });
            })
          );
        }
      }).then(() => {
        const current_project = (vuex.state as any).User.project;
        const projects_list = (vuex.state as any).Projects.list;

        // User has no access to this project
        if (projects_list && projects_list.length && current_project && projects_list.findIndex((project) => project.id === current_project.id) === -1) {
          vuex.commit('User/unsetProject');
          router.push('/');
        }
      });
    }catch (e){}
  }

  @Action({ commit: 'add' }) create(data) {
    if (!data || !data.name) return;
    return API.post(Types.PROJECTS, '', data)
      .then((res) => {
        vuex.dispatch('Global/throwNotify', {
          type: 'success',
          title: 'Success!',
          text: `Project successfully created`
        });

        return res;
      })
      .catch(() => {
        vuex.dispatch('Global/throwNotify', {
          type: 'error',
          title: 'Error!',
          text: `Project did not created`
        });
      });
  }

  @Mutation flush() {
    this.list = [];
    this.projects_count = -1;
  }

  @Mutation
  setProjectsList(project) {
    this.list.push(project);
    vuex.commit('Projects/sortProjectsList');
  }

  @Mutation
  setProjectsCount(length) {
    this.projects_count = length;
  }

  @Mutation 
  updateProjectData(project_metadata) {
    this.projects_metadata.set(project_metadata.id, cloneDeep(project_metadata.data));
    this.projects_metadata_size = this.projects_metadata.size;
  }

  @Mutation 
  clearProjectsData() {
    this.projects_metadata = new Map();
    this.projects_metadata_size = 0;
  }

  @Mutation
  sortProjectsList() {
    this.list.sort((project1, project2) =>
      project1.name.localeCompare(project2.name)
    );
  }

  @Mutation
  updateRestrictedByProject(property){
    const project = this.list.find((project) => project.id === property.project_id);
    if (!project) return;
    
    project.restricted = property.value;
  }

  @Action async add(data: Project) {
    if (!data || !data.id || Utils.find((vuex.state as any).Projects.list, data.id) !== -1) return;
    let hasElectricalPower;

    API.get(Types.COMPANIES,
      `${data.company}/projects/${data.id}/properties/`,
      { name: 'services.config' }
    ).then((res) => {
      let window = null;
      if (res) {
        window =
          res.results &&
          res.results[0] &&
          res.results[0].value &&
          res.results[0].value.alerts &&
          res.results[0].value.alerts.window
            ? { ...res.results[0].value.alerts.window }
            : null;
        if (window && Object.keys(window).length) {
          window.start = Utils.getDateWithSpecificTime(window.start, +localStorage.getItem('now'));
          window.end = Utils.getDateWithSpecificTime(window.end, +localStorage.getItem('now'));
        }
        hasElectricalPower = !(
          !window ||
          (window.start &&
            window.end &&
            +localStorage.getItem('now') >= window.end &&
            +localStorage.getItem('now') <= window.start)
        );
      }
    });
    API.get(Types.PROJECTS, `${data.id}/streams/`).then((res) => {
      if (res && res.results) {
        const notificationStream = res.results.find(
          (s) => s.name === 'dashboard_notifications'
        );
        if (notificationStream) {
          data.notificationStreamId = notificationStream.id;
        } else {
          data.notificationStreamId = '';
        }
      } else {
        data.notificationStreamId = '';
      }
    });
    data.created = data.created ? new Date(data.created) : null;
    data.modified = data.modified ? new Date(data.modified) : null;
    const restricted = await API.get(
      Types.COMPANIES,
      `${data.company}/projects/${data.id}/properties/`,
      { name: 'dashboard.Forbidden_users' }
    );
    const response = await API.get(
      Types.COMPANIES,
      `${data.company}/projects/${data.id}/properties/`,
      { name: 'project.group_id' }
    );

    data.group_id =
      !response ||
      !response.results ||
      !response.results[0] ||
      !response.results[0].value ||
      !response.results[0].value.id ||
      response === ''
        ? -1
        : response.results[0].value.id;
    
    data.restricted = restricted !== undefined && restricted !== '' && restricted.results
      ? restricted.results[0].value
      : [];
    
    vuex.commit('Projects/setProjectsList', { ...data });
    API.post(
      Types.COMPANIES,
      data.company + '/projects/' + data.id + '/reporting_query/',
      {
        columns: ['meta.commission', 'meta.device', 'sys___active', 'sys___connected', 'disconnect_alert'],
        where: ["(class_name IN ['NB_BLE_nRF52V2', 'NB_BLE_nRF52V3', 'NB_BLE_nRF52V1', 'Lora_BLE_nRF52V3', 'SC220_NEMA', 'SC220_ZHAGA']) AND `meta.commission.commissioned`=true"],
        items: 200000
      },
      {}
    ).then(async (response) => {
      const commissioned_devices = response.results;
      for (const device of commissioned_devices) {
        device['meta.commission'] = PropUtils.parseProperty('meta.commission', device);
        device['meta.device'] = PropUtils.parseProperty('meta.device', device);
        PropUtils.reorderMetaDevice(device['meta.device']);
      }
      const project_metadata = {
        id: data.id, 
        data: {totalDevices: 0, activity: [], painted: [], screw: [], pole: [], power: [], commissionedByDate: []}
      };
      project_metadata.data.totalDevices = commissioned_devices.length;

      const painted = [{ place: 'Painted', value: 0 }, { place: 'Not painted', value: 0 }, { place: 'Unspecified', value: 0 }];
      const screwType = [{ place: 'Regular', value: 0 }, { place: 'Fragile', value: 0 }, { place: 'Unspecified', value: 0 }];
      const activity = [
        { place: 'Active devices', value: 0 },
        { place: 'Electrical fault', value: 0 },
        { place: 'In installation', value: 0 },
        { place: 'Fault In Care', value: 0 },
        { place: 'Maintenance', value: 0}
      ];
      
      // set painted count
      const has_painted_field = commissioned_devices.filter(
        (device) => 
          device['meta.device'] &&
          device['meta.device'].is_pole_painted
      );
      painted[0].value = has_painted_field.filter((device) => device['meta.device'].is_pole_painted.toLowerCase() === 'yes').length;
      painted[1].value = has_painted_field.filter((device) => device['meta.device'].is_pole_painted.toLowerCase() === 'no').length;
      painted[2].value = commissioned_devices.length - painted[0].value - painted[1].value;
      project_metadata.data.painted = painted;

      // set screw type count
      const has_screw_field = commissioned_devices.filter(
        (device) => device['meta.device'] && device['meta.device'].screw_type
      );
      screwType[0].value = has_screw_field.filter((device) => device['meta.device'].screw_type.toLowerCase() === 'regular').length;
      screwType[1].value = has_screw_field.filter((device) => device['meta.device'].screw_type.toLowerCase() === 'fragile').length;
      screwType[2].value = commissioned_devices.length - screwType[0].value - screwType[1].value;
      project_metadata.data.screw = screwType;

      // set active status count
      commissioned_devices.forEach((device) => {
        if (device['sys___active'] === -1) activity[4].value++;
        else if (device['sys___active'] === -3) activity[2].value++;
        else if (device['sys___active'] === -9) activity[3].value++;
        else if (device['sys___active'] === 0) {
          if (!hasElectricalPower || (device.hasOwnProperty('disconnect_alert') && +device['disconnect_alert']) === 1) {
            activity[1].value++;
          } else if (hasElectricalPower && device.hasOwnProperty('disconnect_alert') && +device['disconnect_alert'] !== 1)
            activity[0].value++;
        } else activity[0].value++;
      });
      project_metadata.data.activity = activity;
      
      const commissioned_timestamp = [];

      // set pole type and power distribution count
      const poleType = {};
      const unspecifiedPole = { place: 'Unspecified', value: 0};
      const otherPole = { place: 'Other value', value: 0 };
      const power = {};
      const unspecifiedPower = { place: 'Unspecified', value: 0};
      const otherPower = { place: 'Other value', value: 0 };

      for (const device of commissioned_devices) {
        if (!device['meta.device'] || !device['meta.device'].watts){
          unspecifiedPower.value++;
        }else {
          const watts_value = device['meta.device'].watts;

          power.hasOwnProperty(`${watts_value}`)
            ? power[`${watts_value}`]++
            : power[`${watts_value}`] = 1;
        }

        if (!device['meta.device'] || !device['meta.device'].pole_type){
          unspecifiedPole.value++;
        }else {
          const pole_value = device['meta.device'].pole_type;
          poleType.hasOwnProperty(`${pole_value}`)
            ? poleType[`${pole_value}`]++
            : poleType[`${pole_value}`] = 1;
        }

        const commission_datetime = Utils.convertCommissionDate(device['meta.commission'].date, 'YYYY-MM-DD HH:mm:ss');
        const commission_timestamp = commission_datetime && moment(commission_datetime).valueOf();
        if (commission_timestamp) commissioned_timestamp.push(commission_timestamp);
      }

      // set commissioned timestamps
      commissioned_timestamp.sort((a, b) => (a > b ? 1 : -1));
      project_metadata.data.commissionedByDate = commissioned_timestamp;

      // clear unnecessary pole types
      if (poleType.hasOwnProperty('קוני')){
        const connie_pole_count = poleType['קוני'];
        delete poleType['קוני'];
        poleType['Connie'] = poleType.hasOwnProperty('Connie') ? poleType['Connie'] + connie_pole_count : connie_pole_count;
      }

      if (poleType.hasOwnProperty('octagon')) {
        poleType['Octagon'] = poleType.hasOwnProperty('Octagon') ? poleType['Octagon'] + poleType['octagon'] : poleType['octagon'];
        delete poleType['octagon'];
      }

      let poleTypeChartData = Object.entries(poleType).map(([type, count]) => ({ place: type, value: count }));
      poleTypeChartData.sort((a, b) => Number(b.value) - Number(a.value));
      if (poleTypeChartData.length > 6) {
        poleTypeChartData.slice(5).forEach((pole_data) => otherPole.value += Number(pole_data.value));
        poleTypeChartData = poleTypeChartData.slice(0, 5);
      }

      if (unspecifiedPole.value) poleTypeChartData.push(unspecifiedPole);
      if (otherPole.value) poleTypeChartData.push(otherPole);
      project_metadata.data.pole = cloneDeep(poleTypeChartData);

      // clear unnecessary power values
      let powerChartData = Object.entries(power).map(([type, count]) => ({place: `${type} Watt`, value: count}));
      powerChartData.sort((a, b) => Number(b.value) - Number(a.value));

      if (powerChartData.length > 6) {
        powerChartData.slice(5).forEach((power_data) => otherPower.value += Number(power_data.value));
        powerChartData = powerChartData.slice(0, 5);
      }

      if (unspecifiedPower.value) powerChartData.push(unspecifiedPower);
      if (otherPower.value) powerChartData.push(otherPower);
      project_metadata.data.power = cloneDeep(powerChartData);
      vuex.commit('Projects/updateProjectData', project_metadata);
    });
  }

  @Action async getAlerts() {
    const project_data = (vuex.state as any).User.project;

    if (!project_data) return;

    const url = 'incidents/cabinets_list?query=tags:alert';
    const headers = {
      'company': project_data.company
    };

    if (project_data.id !== 'allProjects'){
      headers['project'] = project_data.id;
    }

    try {
      const response = await API.dashboardAPI(url, 'POST', headers, {});
      vuex.commit('Projects/setAlerts', response);
    } catch (e) {
      console.error(e);
    }
  }

  @Mutation
  deleteIncident(ticket_data) {
    const new_incidents = cloneDeep(this[ticket_data.incidents_type]);
    const new_cabinet_incidents_list = new_incidents[ticket_data.project_id]['cabinets'][ticket_data.cabinet_id].list.filter((incident) => incident.id.toString() !== ticket_data.id.toString());
    if (new_cabinet_incidents_list.length === 0) {
      delete new_incidents[ticket_data.project_id]['cabinets'][ticket_data.cabinet_id];
    }else {
      new_incidents[ticket_data.project_id]['cabinets'][ticket_data.cabinet_id].list = new_cabinet_incidents_list;
    }
    
    this[ticket_data.incidents_type] = new_incidents;
    vuex.commit('User/removeIncident', ticket_data);
  }

  @Mutation setAlerts(alerts) {
    const new_alerts_list = cloneDeep(alerts);
    Object.entries(new_alerts_list).forEach(([project_id, alerts_data]) => new_alerts_list[project_id] = alerts_data);
    this.alerts = new_alerts_list;
  }

  @Mutation setEvents(events) {
    const new_events_list = cloneDeep(events);
    Object.entries(new_events_list).forEach(([project_id, events_data]) => new_events_list[project_id] = events_data);
    this.events = new_events_list;
  }

  @Action async getEvents() {
    const project_data = (vuex.state as any).User.project;
    const user = await JSON.parse(localStorage.getItem('user'));
    if (!project_data) return;
    
    const url = 'incidents/cabinets_list?query=tags:event&limit=100';
    const headers = {
      'company': user.company
    };

    if (project_data.id !== 'allProjects'){
      headers['project'] = project_data.id;
    }

    try {
      const response = await API.dashboardAPI(url, 'POST', headers, {});
      vuex.commit('Projects/setEvents', response);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  async loadImage(project_data) {
    const image = {
      [project_data.project_id]: {
        url: '@/assets/images/tondo_icons/default_header_logo.svg',
        color: 'rgba(0, 0, 0, 0.05)'
      }
    };

    const logoProperty = await API.get(
      Types.COMPANIES,
      `${project_data.company_id}/projects/${project_data.project_id}/properties/`,
      { name: 'meta.logo' }
    );

    const meta_logo =
      logoProperty &&
      logoProperty.results &&
      logoProperty.results[0].value &&
      logoProperty.results[0].value
        ? logoProperty.results[0].value
        : '';

    if (meta_logo['url']) image[project_data.project_id].url = meta_logo['url'];
    if (meta_logo['color'])
      image[project_data.project_id].color = meta_logo['color'];

    vuex.commit('Projects/addImage', image);
  }

  @Mutation
  addImage(image) {
    this.images = { ...this.images, ...image };
  }

  @Mutation
  changeImage(data) {
    this.images[data[0]] = data[1];
  }
}
